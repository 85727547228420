/* tslint:disable */
/* eslint-disable */
/**
 * Main Service API
 * Main Service API
 *
 * The version of the OpenAPI document: 2.11.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CancelSubscriptionDto } from '../models';
// @ts-ignore
import { CreateStripeCustomerDto } from '../models';
// @ts-ignore
import { CreateSubscriptionSessionDto } from '../models';
// @ts-ignore
import { GetPortalDto } from '../models';
// @ts-ignore
import { GetStripeCustomerDto } from '../models';
/**
 * SubscriptionsApi - axios parameter creator
 * @export
 */
export const SubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {CancelSubscriptionDto} cancelSubscriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription: async (cancelSubscriptionDto: CancelSubscriptionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cancelSubscriptionDto' is not null or undefined
            assertParamExists('cancelSubscription', 'cancelSubscriptionDto', cancelSubscriptionDto)
            const localVarPath = `/main/public/subscriptions/cancel-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelSubscriptionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateStripeCustomerDto} createStripeCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStripeCustomer: async (createStripeCustomerDto: CreateStripeCustomerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createStripeCustomerDto' is not null or undefined
            assertParamExists('createStripeCustomer', 'createStripeCustomerDto', createStripeCustomerDto)
            const localVarPath = `/main/public/subscriptions/create-stripe-customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStripeCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateSubscriptionSessionDto} createSubscriptionSessionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionSession: async (createSubscriptionSessionDto: CreateSubscriptionSessionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSubscriptionSessionDto' is not null or undefined
            assertParamExists('createSubscriptionSession', 'createSubscriptionSessionDto', createSubscriptionSessionDto)
            const localVarPath = `/main/public/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSubscriptionSessionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {GetPortalDto} getPortalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortal: async (getPortalDto: GetPortalDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getPortalDto' is not null or undefined
            assertParamExists('getPortal', 'getPortalDto', getPortalDto)
            const localVarPath = `/main/public/subscriptions/portal-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPortalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/public/subscriptions/prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {GetStripeCustomerDto} getStripeCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeCustomer: async (getStripeCustomerDto: GetStripeCustomerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getStripeCustomerDto' is not null or undefined
            assertParamExists('getStripeCustomer', 'getStripeCustomerDto', getStripeCustomerDto)
            const localVarPath = `/main/public/subscriptions/get-stripe-customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getStripeCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeSubscriptionList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/public/subscriptions/get-stripe-subscription-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionsApi - functional programming interface
 * @export
 */
export const SubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CancelSubscriptionDto} cancelSubscriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSubscription(cancelSubscriptionDto: CancelSubscriptionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscription(cancelSubscriptionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateStripeCustomerDto} createStripeCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStripeCustomer(createStripeCustomerDto: CreateStripeCustomerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStripeCustomer(createStripeCustomerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateSubscriptionSessionDto} createSubscriptionSessionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscriptionSession(createSubscriptionSessionDto: CreateSubscriptionSessionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscriptionSession(createSubscriptionSessionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {GetPortalDto} getPortalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortal(getPortalDto: GetPortalDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortal(getPortalDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {GetStripeCustomerDto} getStripeCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStripeCustomer(getStripeCustomerDto: GetStripeCustomerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStripeCustomer(getStripeCustomerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStripeSubscriptionList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStripeSubscriptionList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionsApi - factory interface
 * @export
 */
export const SubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CancelSubscriptionDto} cancelSubscriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription(cancelSubscriptionDto: CancelSubscriptionDto, options?: any): AxiosPromise<void> {
            return localVarFp.cancelSubscription(cancelSubscriptionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateStripeCustomerDto} createStripeCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStripeCustomer(createStripeCustomerDto: CreateStripeCustomerDto, options?: any): AxiosPromise<object> {
            return localVarFp.createStripeCustomer(createStripeCustomerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateSubscriptionSessionDto} createSubscriptionSessionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionSession(createSubscriptionSessionDto: CreateSubscriptionSessionDto, options?: any): AxiosPromise<object> {
            return localVarFp.createSubscriptionSession(createSubscriptionSessionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {GetPortalDto} getPortalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortal(getPortalDto: GetPortalDto, options?: any): AxiosPromise<object> {
            return localVarFp.getPortal(getPortalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrices(options?: any): AxiosPromise<object> {
            return localVarFp.getPrices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {GetStripeCustomerDto} getStripeCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeCustomer(getStripeCustomerDto: GetStripeCustomerDto, options?: any): AxiosPromise<object> {
            return localVarFp.getStripeCustomer(getStripeCustomerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeSubscriptionList(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getStripeSubscriptionList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionsApi - object-oriented interface
 * @export
 * @class SubscriptionsApi
 * @extends {BaseAPI}
 */
export class SubscriptionsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CancelSubscriptionDto} cancelSubscriptionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public cancelSubscription(cancelSubscriptionDto: CancelSubscriptionDto, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).cancelSubscription(cancelSubscriptionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateStripeCustomerDto} createStripeCustomerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public createStripeCustomer(createStripeCustomerDto: CreateStripeCustomerDto, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).createStripeCustomer(createStripeCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateSubscriptionSessionDto} createSubscriptionSessionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public createSubscriptionSession(createSubscriptionSessionDto: CreateSubscriptionSessionDto, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).createSubscriptionSession(createSubscriptionSessionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {GetPortalDto} getPortalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public getPortal(getPortalDto: GetPortalDto, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).getPortal(getPortalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public getPrices(options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).getPrices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {GetStripeCustomerDto} getStripeCustomerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public getStripeCustomer(getStripeCustomerDto: GetStripeCustomerDto, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).getStripeCustomer(getStripeCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public getStripeSubscriptionList(options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).getStripeSubscriptionList(options).then((request) => request(this.axios, this.basePath));
    }
}
