import React, { useEffect, useState } from "react";
import { fontSize, styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Link } from "@mui/material";

interface ResultItemProps {
	icon: string;
	source: string;
	url: string;
	title: string;
	description: string;
	tag: string;
}

const ResultItem: React.FC<ResultItemProps> = ({
	icon,
	source,
	url,
	title,
	description,
	tag,
}) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);
	useEffect(() => {
		if (width <= 500) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [width]);
	return (
		<ResultContainer>
			<Link
				target="_blank"
				href={url}
				sx={{
					textDecoration: "none",
					":hover #resultTitle": {
						textDecoration: "underline",
					},
				}}
			>
				<SourceInfo>
					<IconWrapper>
						<Avatar
							src={icon}
							alt={source}
							sx={{
								width: "16px",
								height: "16px",
							}}
						/>
					</IconWrapper>
					<SourceDetails>
						<SourceName>{source}</SourceName>
						{!isMobile ? (
							<SourceUrl>
								{url && url?.substring(0, 57)}
								{url && url.length > 57 ? "..." : ""}
							</SourceUrl>
						) : (
							<SourceUrl>
								{url && url?.substring(0, 22)}
								{url && url.length > 22 ? "..." : ""}
							</SourceUrl>
						)}
					</SourceDetails>
				</SourceInfo>

				{!isMobile ? (
					<ResultTitle id="resultTitle">
						{title && title?.substring(0, 57)}
						{title && title.length > 57 ? "..." : ""}
					</ResultTitle>
				) : (
					<ResultTitle id="resultTitle">
						{title && title?.substring(0, 40)}
						{title && title.length > 40 ? "..." : ""}
					</ResultTitle>
				)}
			</Link>
			<ResultDescription>
				{description && description?.substring(0, 150)}
				{description && description.length > 150 ? "..." : ""}
			</ResultDescription>
			{tag && (
				<ResultTag>
					{tag}
					{" resource"}
				</ResultTag>
			)}
		</ResultContainer>
	);
};

const ResultContainer = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	padding: "0 0 4px",
	// cursor: "pointer",
	[theme.breakpoints.down("lg")]: {
		maxWidth: "100%",
	},
	[theme.breakpoints.down("sm")]: {
		maxWidth: "100%",
	},
}));

const SourceInfo = styled(Link)(({ theme }) => ({
	display: "flex",
	gap: "8px",
	textDecoration: "none",
	[theme.breakpoints.down("lg")]: {
		// flexWrap: "wrap",
		// paddingRight: "20px",
		// marginTop: "40px",
	},
}));

const IconWrapper = styled(Box)({
	borderRadius: "3.5px",
	backgroundColor: "#ffff",
	color: "#FFFFFF",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	width: "24px",
	height: "24px",
	padding: "6px",
	border: "1px solid #D3D9E3",
	gap: "8px",
	// boxShadow: "0 0 3px rgba(0, 0, 0, 0.1)",
});

const SourceDetails = styled(Box)({
	display: "flex",
	gap: "4px",
	fontSize: 12,
	color: "#4d4e53",
	whiteSpace: "nowrap",
	cursor: "pointer",
	letterSpacing: "0.02em",
	margin: "auto 0",
	"@media (max-width: 991px)": {
		whiteSpace: "initial",
	},
});

const SourceName = styled(Typography)({
	fontFamily: "Inter",
	fontWeight: 600,
	fontSize: "12px",
	"@media (max-width: 400px)": {
		fontSize: "10px",
	},
});

const SourceUrl = styled(Typography)({
	fontFamily: "Inter",
	fontWeight: 400,
	fontSize: "12px",
	"@media (max-width: 400px)": {
		fontSize: "10px",
	},
});

const ResultTitle = styled(Typography)(({ theme }) => ({
	fontFamily: "Inter",
	color: "#323339",
	marginTop: "8px",
	fontWeight: 600,
	fontSize: "20px",
	lineHeight: "26px",
	cursor: "pointer",
	":hover": {
		textDecoration: "underline",
	},
	[theme.breakpoints.down("lg")]: {
		maxWidth: "100%",
		// fontSize: "16px",
	},
	[theme.breakpoints.down("sm")]: {
		maxWidth: "100%",
		fontSize: "16px",
	},
	"@media (max-width: 400px)": {
		maxWidth: "100%",
		fontSize: "14px",
	},
}));

const ResultDescription = styled(Typography)({
	fontFamily: "Inter",
	color: "#4d4e53",
	marginTop: "4px",
	fontSize: "14px",
	lineHeight: "20px",
	"@media (max-width: 991px)": {
		maxWidth: "100%",
	},
});

const ResultTag = styled(Box)({
	borderRadius: "4px",
	backgroundColor: "#f5f7fc",
	alignSelf: "start",
	marginTop: "16px",
	color: "#040404",
	letterSpacing: "0.02em",
	lineHeight: "20px",
	padding: "2px 6px",
	fontWeight: 500,
	fontSize: "13px",
});

export default ResultItem;
