import { action } from 'easy-peasy';


export enum IFeedbackType {
  NONE = 'none',
  POSITIVE = 'positive',
  NEGATIVE = 'negative'
}


// Define the user model
export interface IFeedbackModel {
  data: any
  isLoading: boolean
  setData: Function | any
  setFeedback: Function | any
  setLoading: Function | any
  clearData: Function | any
}

export interface ISubmitFeedback {
  id: number
  feedback: IFeedbackType
}

const FeedbackModel: IFeedbackModel = {
  data: {},
  isLoading: false,
  setLoading: action((state: any, payload: any) => {
    state.isLoading = payload
  }),
  setData: action((state: any, payload: any) => {
    const data = payload.reduce((acc: any, cur: any) => {
      if (cur) {
        acc[cur.historyId ?? cur.id] = cur.feedback
      }
      return acc;
    }, {})
    state.data = data;
  }),
  setFeedback: action((state: any, payload: ISubmitFeedback) => {
    state.data[payload.id] = payload.feedback
  }),
  clearData: action((state: any) => {
    state.isLoading = false
    state.data = {}
  }),
};

export default FeedbackModel