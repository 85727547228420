import { Box, Typography } from "@mui/material";
import {
	// ErrorMessage,
	// Field,
	Form,
	Formik,
	FormikHelpers,
	// useFormikContext,
} from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { ISignUpFormValues } from "../../interfaces/ISignUpFormValues";
import FormWrapper from "../common/FormWrapper";
import { FormAlert } from "./FormAlert";
import { FormButton } from "./FormButton";
import { FormField } from "./FormField";
import FormTitle from "./FormTitle";
import SocialLogin from "./SocialLogin";

const SignUpForm = () => {
	const navigate = useNavigate();
	const { showSnackbar } = useAlert();
	const { handleCreateUser, loading, error, setError } = useAuth();

	const signUpformValues: ISignUpFormValues = {
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		// confirmPassword: "",
		acceptTerms: false,
	};

	const [formValues, setFormValues] = useState(signUpformValues);

	const validationSchema = yup.object().shape({
		firstName: yup.string().min(3).required().label("Full name"),
		// lastName: yup.string().min(3).required().label("Last name"),
		email: yup.string().email("Invalid email").required().label("Email"),
		password: yup.string().min(8).required().label("Password"),
		acceptTerms: yup
			.boolean()
			.required("Please accept terms & conditions.")
			.oneOf([true], "Please accept terms & conditions."),
		// confirmPassword: yup
		// 	.string()
		// 	.oneOf([yup.ref("password")], "Passwords must match")
		// 	.required()
		// 	.label("Confirm password"),
	});

	const onSubmit = async (
		values: ISignUpFormValues,
		actions: FormikHelpers<ISignUpFormValues>
	) => {
		try {
			// console.log("values", values);
			actions.setSubmitting(true);
			setFormValues(values);
			const response = await handleCreateUser(values);
			if (response) {
				navigate("/verify-email");
			}
		} catch (error: any) {
			showSnackbar(error.message, { variant: "error" });
		} finally {
			actions.setSubmitting(false);
		}
	};

	const handleSignInClick = () => {
		navigate("/");
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			width={"100%"}
		>
			<FormWrapper>
				{!loading && error && (
					<FormAlert error={error} setError={setError} />
				)}
				<FormTitle title={"Sign up"} />
				<Formik
					initialValues={formValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
					// enableReinitialize
				>
					{(formik) => {
						return (
							<Form>
								<FormField
									name="firstName"
									label="Full Name"
									placeholder="Full Name"
								/>
								{/* <FormField
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter Last Name"
                /> */}
								<FormField
									name="email"
									label="Email"
									placeholder="Email"
								/>

								<FormField
									name="password"
									label="Password"
									placeholder="Password"
									type="password"
									marginBottom="0px"
								/>
								<FormField
									name="acceptTerms"
									label={"Accept "}
									type="checkbox"
									disabled={loading}
								/>

								<FormButton
									label={"Sign up"}
									loading={loading}
								/>

								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										marginBottom: "32px",
										width: "100%",
									}}
								>
									<SocialLogin label="Sign up with Google" />
								</Box>
								<Typography
									textAlign="center"
									color={"#A6A8AC"}
									fontSize={"12px"}
									lineHeight={"24px"}
									fontFamily={"Inter"}
									fontWeight={500}
								>
									Already have an account?
									<Typography
										component={"span"}
										// ml={1}
										// color="primary"
										display="inline-block"
										onClick={handleSignInClick}
										sx={{
											cursor: "pointer",
											color: "#1472FF",
											fontWeight: 500,
											fontFamily: "Inter",
											fontSize: "12px",
											lineHeight: "24px",
											textAlign: "center",
											"&:hover": {
												textDecoration: "underline",
											},
										}}
									>
										&nbsp;Login
									</Typography>
								</Typography>
							</Form>
						);
					}}
				</Formik>
			</FormWrapper>
		</Box>
	);
};

export default SignUpForm;
