import { Box, Link, Typography } from "@mui/material";
import React from "react";
// import FullLogo from "../../svg-components/FullLogo";
import FullLogoLarger from "../../svg-components/FullLogoLarger";
import useDataset from "../../hooks/useDataset";
import { IQueryType } from "../../redux/models/datasetModel";
import Question from "../../svg-components/Question";

const TopHeading = () => {
	const { queryType } = useDataset();

	return (
		<Box
			sx={{
				textAlign: "center",
				marginBottom: "64px",
				marginTop: "64px",
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				gap: "16px",
			}}
		>
			<FullLogoLarger />
			<Typography
				fontWeight={"700"}
				fontSize={"24px"}
				lineHeight={"29px"}
				color={"#323339"}
				textAlign={"center"}
				fontFamily={"Inter"}
				sx={{
					"@media (max-width: 400px)": {
						fontSize: "20px",
					},
				}}
			>
				AI-powered{" "}
				{queryType === IQueryType.MARKETING ? "Growth" : "Fundraising"}{" "}
				Search
			</Typography>
			<Link
				href="https://www.shome.how/how-it-works"
				target="_blank"
				underline="none"
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "4px",
				}}
			>
				<Question />
				<Typography
					sx={{
						fontWeight: "500",
						fontSize: "14px",
						lineHeight: "17px",
						color: "#323339",
						textAlign: "center",
						fontFamily: "Inter",
					}}
				>
					How our search works
				</Typography>
			</Link>
			{/* <Typography
				component={"p"}
				fontWeight={"400"}
				fontSize={"12px"}
				lineHeight={"24px"}
				color={"#8491A3"}
				letterSpacing={"0.02em"}
				textAlign={"center"}
				fontFamily={"Inter"}
			>
				Ask your early stage startup{" "}
				{queryType === IQueryType.MARKETING ? "growth" : "fundraising"}{" "}
				questions to find the best links online.&nbsp;
				<span style={{ color: "#2D60C2" }}>
					<Link
						href="https://www.shome.how/how-it-works"
						target="_blank"
						underline="always"
					>
						Learn more.
					</Link>
				</span>
			</Typography> */}
		</Box>
	);
};

export default TopHeading;
