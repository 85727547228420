import { Button, ButtonProps } from "@mui/material";
import { FC } from "react";
import { theme } from "../../theme";

interface IButton extends ButtonProps {
  text: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  style?: React.CSSProperties;
  size?: "small" | "medium" | "large" | undefined;
  fullWidth?: boolean;
}
const PrimaryButton: FC<IButton> = (props) => {
  const { text, icon } = props;
  const Wrapper = {
    color: theme.palette.primary.contrastText,
    textTransform: "none",
    padding: '12px'
  };
  return (
    <Button
      startIcon={icon}
      variant="contained"
      {...props}
      disableElevation
      disableRipple
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type}
      sx={{ ...Wrapper, ...props.style }}
      size={props.size}
      fullWidth={props.fullWidth}
    >
      {text}
    </Button>
  );
};
export default PrimaryButton;
