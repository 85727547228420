import PageWrapper from "../components/common/PageWrapper";
import ResetPasswordForm from "../components/form/ResetPasswordForm";

const ResetPasswordPage = () => {

  return (
    <PageWrapper>
      <ResetPasswordForm />
    </PageWrapper>
  );
};

export default ResetPasswordPage;
