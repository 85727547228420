const ArrowTrendingUp = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9 2.5C8.44771 2.5 8 2.94772 8 3.5C8 4.05228 8.44771 4.5 9 4.5H12.6664L8.7059 8.98857L7.68941 7.83656C6.92614 6.97151 5.59128 6.92984 4.77553 7.74558L0.292894 12.2282C-0.0976305 12.6187 -0.0976316 13.2519 0.292892 13.6424C0.683416 14.0329 1.31658 14.0329 1.70711 13.6424L6.18974 9.1598L7.20622 10.3118C8.00241 11.2142 9.40939 11.2142 10.2056 10.3118L14 6.01147V9.5C14 10.0523 14.4477 10.5 15 10.5C15.5523 10.5 16 10.0523 16 9.5V3.81579C16 3.0891 15.4109 2.5 14.6842 2.5H9Z"
				fill="#515151"
				style={{
					fill: "#515151",
					color: "display-p3 0.3167 0.3167 0.3167",
					fillOpacity: 1,
				}}
			/>
		</svg>
	);
};

export default ArrowTrendingUp;
