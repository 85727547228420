import { action } from 'easy-peasy';
import { ChatHistoryResponse } from '../../openapi'



export interface ISetDataset {
  historyData: Array<ChatHistoryResponse>
}
export interface IAddNewHistory {
  question: string
  threadId: number
}

// Define the user model
export interface IChatHistoryModel {
  historyData: undefined | Array<ChatHistoryResponse>
  isLoading: boolean
  isDrwaerOpen: boolean
  setData: Function | any
  setLoading: Function | any
  addData: Function | any
  clearData: Function | any
  setDrwaerOpen: Function | any
  searchNumberThisMonth: number
  setSearchNumberThisMonth: Function | any
}

const ChatHistoryModel: IChatHistoryModel = {
  isLoading: false,
  historyData: undefined,
  isDrwaerOpen: false,
  searchNumberThisMonth: 0,
  setSearchNumberThisMonth: action((state: any, payload: any) => {
    state.searchNumberThisMonth = payload
  }),
  setDrwaerOpen: action((state: any, payload: any) => {
    state.isDrwaerOpen = payload
  }),
  setLoading: action((state: any, payload: any) => {
    state.isLoading = payload
  }),
  setData: action((state: any, payload: ISetDataset) => {
    state.historyData = payload.historyData
    state.isLoading = false
  }),
  addData: action((state: any, payload: IAddNewHistory) => {
    if (state.historyData) {
      const findData: any = []
      const otherData: ChatHistoryResponse[] = []

      state.historyData.forEach((element: ChatHistoryResponse) => {
        if (element.threadId === payload.threadId) {
          findData.push({
            ...element,
            question: payload.question,
          })
        } else {
          otherData.push(element)
        }
      })

      state.historyData = [...findData, ...otherData]
    } else {
      state.historyData = [payload]
    }
    state.isLoading = false
  }),
  clearData: action((state: any) => {
    state.historyData = undefined;
    state.isLoading = false
  }),
};

export default ChatHistoryModel