const Question = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.06777 11.8334C9.06777 11.8334 8.9511 11.5417 8.9511 11.3084C8.9511 10.3944 9.52999 9.97424 10.0623 9.5879C10.5152 9.25913 10.9344 8.95487 10.9344 8.39169C10.9344 7.92502 10.5261 7.51669 9.88443 7.51669C8.7761 7.51669 8.48443 8.45002 8.48443 8.45002L7.0261 7.80835C7.0261 7.80835 7.60943 5.82502 9.94277 5.82502C11.6344 5.82502 12.7428 6.93335 12.7428 8.15835C12.7428 9.33246 12.1213 9.86787 11.5546 10.3562C11.0795 10.7655 10.6428 11.1417 10.6428 11.8334H9.06777Z"
				fill="#323339"
				style={{
					fill: "#323339",
					color: "display-p3 0.1961 0.2000 0.2235",
					fillOpacity: 1,
				}}
			/>
			<path
				d="M9.76777 14.2834C9.24277 14.2834 8.83443 13.875 8.83443 13.4084C8.83443 12.9417 9.24277 12.5334 9.76777 12.5334C10.2928 12.5334 10.7011 12.9417 10.7011 13.4084C10.7011 13.875 10.2928 14.2834 9.76777 14.2834Z"
				fill="#323339"
				style={{
					fill: "#323339",
					color: "display-p3 0.1961 0.2000 0.2235",
					fillOpacity: 1,
				}}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.3333 10C18.3333 14.6024 14.6023 18.3334 9.99996 18.3334C5.39759 18.3334 1.66663 14.6024 1.66663 10C1.66663 5.39765 5.39759 1.66669 9.99996 1.66669C14.6023 1.66669 18.3333 5.39765 18.3333 10ZM16.6666 10C16.6666 13.6819 13.6819 16.6667 9.99996 16.6667C6.31806 16.6667 3.33329 13.6819 3.33329 10C3.33329 6.31812 6.31806 3.33335 9.99996 3.33335C13.6819 3.33335 16.6666 6.31812 16.6666 10Z"
				fill="#323339"
				style={{
					fill: "#323339",
					color: "display-p3 0.1961 0.2000 0.2235",
					fillOpacity: 1,
				}}
			/>
		</svg>
	);
};

export default Question;
