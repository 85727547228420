import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NewSearchBar from "./NewSearchBar";
import PopularSearches from "./PopularSearches";
import Header from "../../layout/Header";
import {
	CircularProgress,
	circularProgressClasses,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import { IQueryType, ISingleDataset } from "../../redux/models/datasetModel";
import useDataset from "../../hooks/useDataset";
import {
	POPULAR_QUESTIONS,
	MARKETING_QUESTIONS,
	FundRaisingHomeQuestions,
	MarketingHomeQuestions,
} from "../../utils/constants";
import useHistory from "../../hooks/useHistory";
import useAuth from "../../hooks/useAuth";
import CustomLoader from "../result-item/CustomLoader";
import ResultItems from "./ResultItems";
import TopHeading from "../../pages/home/TopHeading";
import NewHomeSearchBar from "./NewHomeSearchBar";
import PopularSearchesHome from "./PopularSearchesHome";
import { theme } from "../../theme";

interface SearchResultsProps {
	totalResults: number;
}

// interface SearchBarProps {
// 	value?: string;
// 	width?: string;
// 	onChange?: (value: string) => void;
// 	placeholder?: string;
// 	height?: string;
// 	onCancelResearch?: (value: string) => void;
// 	onSearch?: (value?: string) => void;
// 	className?: string;
// 	style?: React.CSSProperties;
// 	disabled?: boolean;
// 	options?: string[];
// }
const SearchResults: React.FC<SearchResultsProps> = ({ totalResults }) => {
	const {
		datasets,
		exaSearching,
		isExaDataset,
		isLoading,
		// showNoResultText,
		getExaDataset,
		getDataset,
		getThreadId,
		setRandomQuestions,
		setShowPopularQusetion,
		setMaxSearchLimit,
		questionReplaceHandler,
		clearDataset,
		setShowNoResultText,
	} = useDataset();
	const { searchNumberThisMonth } = useHistory();
	const { userInfo } = useAuth();
	const [question, setQuestion] = useState<string>("");
	const [questionManupulated, setQuestionManupulated] =
		useState<boolean>(false);
	const { queryType, setQuryType } = useDataset();
	const handleChangeQueryType = (event: SelectChangeEvent) => {
		setQuryType(event.target.value as IQueryType);
		setRandomQuestions(
			getRandomQuestions(event.target.value as IQueryType)
		);
	};
	const getRandomQuestions = (type?: IQueryType) => {
		const qType = type ? type : queryType;
		const questionSource =
			qType === IQueryType.FUNDRAISINNG
				? POPULAR_QUESTIONS
				: MARKETING_QUESTIONS;
		const selectedQuestions: string[] = [];
		while (selectedQuestions.length < 3) {
			const randomIndex = Math.floor(
				Math.random() * questionSource.length
			);
			const randomQuestion = questionSource[randomIndex];
			if (!selectedQuestions.includes(randomQuestion)) {
				selectedQuestions.push(randomQuestion);
			}
		}
		return selectedQuestions;
	};
	const handleChange = (value: string) => {
		// console.log("handleChange for: ", value);
		const inputText = value;
		setQuestion(inputText ?? "");
		if (datasets && datasets.length > 0) {
			setQuestionManupulated(true);
		}
	};
	const handleQuestionClick = (query: string) => {
		// console.log("handleQuestionClick for: ", query);
		questionReplaceHandler(true, query);
		if (
			(searchNumberThisMonth > 10 &&
				userInfo?.subscriptionType === "Free") ||
			(searchNumberThisMonth > 25 &&
				userInfo?.subscriptionType === "Starter")
		) {
			setShowPopularQusetion(false);
			setMaxSearchLimit(true);
			return;
		} else {
			setMaxSearchLimit(false);
			setRandomQuestions(getRandomQuestions());
			setShowPopularQusetion(true);
			clearDataset();
			getThreadId();
			getDataset(query);
			setQuestion(query);
		}
	};
	const handleSubmit = () => {
		// e.preventDefault();
		// console.log("searching for: ", question);
		if (!question) return;

		try {
			setQuestionManupulated(false);
			if (
				(searchNumberThisMonth > 10 &&
					userInfo?.subscriptionType === "Free") ||
				(searchNumberThisMonth > 25 &&
					userInfo?.subscriptionType === "Starter")
			) {
				setShowPopularQusetion(false);
				setMaxSearchLimit(true);
				return;
			} else {
				setMaxSearchLimit(false);
				setRandomQuestions(getRandomQuestions());
				setShowPopularQusetion(true);
				clearDataset();
				getThreadId();
				getDataset(question);
				setQuestion(question);
			}
		} catch (error: any) {
			console.log("error: ", error);
		}
	};
	const onCancelResearch = (value: string) => {
		// console.log("cancelling research for ", value);
		setQuestionManupulated(true);
		setQuestion("");
	};
	useEffect(() => {
		// if (showNoResultText) {
		// 	console.log("showNoResultText", showNoResultText);
		// 	setShowNoResultText(true);
		// }
		if (datasets && datasets[0]?.result?.length < 1) {
			if (!isExaDataset && !isLoading) {
				// console.log("getExaDataset");
				getExaDataset(question);
				setShowNoResultText(true);
			}
			if (!isExaDataset) {
				// setExaSearching(false);
			}
		}
	}, [
		getExaDataset,
		isExaDataset,
		isLoading,
		question,
		datasets,
		setShowNoResultText,
	]);
	return (
		<Container>
			<Header />
			{datasets || datasets?.length > 0 ? (
				<>
					<Box
						sx={{
							border: "1px solid #D3D9E3",
						}}
					>
						<SearchFormWrapper>
							<FormControlWrapper size="small" fullWidth>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={queryType}
									onChange={handleChangeQueryType}
									sx={{
										height: "40px",
										fontSize: "14px",
										fontWeight: 500,
										color: "#323339",
										// border: "1px solid #C3C6D6",
									}}
									style={{ border: "none" }}
								>
									<MenuItem value={IQueryType.FUNDRAISINNG}>
										Fundraising
									</MenuItem>
									<MenuItem value={IQueryType.MARKETING}>
										Growth
									</MenuItem>
								</Select>
							</FormControlWrapper>
							<NewSearchBar
								question={
									question
										? question
										: question.length === 0 &&
										  questionManupulated
										? ""
										: datasets && datasets.length > 0
										? datasets[0]?.question
										: ""
								}
								setQuestion={setQuestion}
								onSearch={handleSubmit}
								onCancelResearch={onCancelResearch}
								onChange={handleChange}
							/>
						</SearchFormWrapper>
					</Box>
					<BodyWrapper>
						<ContentWrapper>
							<ResultsColumn>
								{datasets &&
									datasets.length > 0 &&
									datasets[0]?.result && (
										<ResultCount>
											Showing{" "}
											{datasets &&
												datasets[0]?.result
													?.length}{" "}
											results{" "}
											{!isExaDataset
												? "from our curated library"
												: "from the world wide web"}
										</ResultCount>
									)}
								<ResultItemWrapper>
									{datasets &&
										datasets?.length > 0 &&
										datasets.map(
											(
												element: ISingleDataset,
												index: number
											) =>
												element.loading || isLoading ? (
													<Box
														key={
															Math.random() +
															index
														}
														width={"100%"}
														gap={"16px"}
													>
														<Typography
															sx={{
																padding:
																	"8px 16px",
																gap: "10px",
																background:
																	"#FFFFFF",
																fontFamily:
																	"Inter",
																fontStyle:
																	"normal",
																fontWeight: 500,
																fontSize:
																	"14px",
																lineHeight:
																	"17px",
																color: "#7A7C80",
																textAlign:
																	"left",
															}}
														>
															<CircularProgress
																variant="indeterminate"
																disableShrink
																sx={{
																	color: (
																		theme: any
																	) =>
																		theme
																			.palette
																			.mode ===
																		"light"
																			? "#1a90ff"
																			: "#308fe8",
																	animationDuration:
																		"550ms",
																	[`& .${circularProgressClasses.circle}`]:
																		{
																			strokeLinecap:
																				"round",
																		},
																	// paddingRight: "10px",
																}}
																size={20}
																thickness={4}
															/>
															{exaSearching ? (
																<span
																	style={{
																		marginLeft:
																			"10px",
																	}}
																>
																	Finding more
																	results
																	outside our
																	curated
																	library!
																	Searching
																	the world
																	wide web.
																</span>
															) : (
																<span
																	style={{
																		marginLeft:
																			"10px",
																	}}
																>
																	Searching
																	our curated
																	library
																</span>
															)}
														</Typography>
														<Box
															width={"100%"}
															bgcolor={"#fff"}
															p={"24px"}
															borderRadius={
																"20px"
															}
														>
															<CustomLoader />
														</Box>
													</Box>
												) : (
													<ResultItems
														key={element.historyId}
														items={element.result}
													/>
												)
										)}
								</ResultItemWrapper>
							</ResultsColumn>
							<PopularSearches onSearch={handleQuestionClick} />
						</ContentWrapper>
					</BodyWrapper>
				</>
			) : (
				<ContentWrapperHome>
					<TopHeading />
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "start",
							gap: "16px",
						}}
					>
						<FormControl
							sx={{
								width: "194px",
								border: "none",
							}}
							size="small"
							fullWidth
						>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={queryType}
								onChange={handleChangeQueryType}
								sx={{
									height: "40px",
									fontSize: "14px",
									fontWeight: 600,
									color: "#323339",
								}}
								style={{ border: "none" }}
							>
								<MenuItem value={IQueryType.FUNDRAISINNG}>
									Fundraising Database
								</MenuItem>
								<MenuItem value={IQueryType.MARKETING}>
									Growth Database
								</MenuItem>
							</Select>
						</FormControl>
						<NewHomeSearchBar
							question={
								question
									? question
									: question.length === 0 &&
									  questionManupulated
									? ""
									: datasets && datasets.length > 0
									? datasets[0]?.question
									: ""
							}
							setQuestion={setQuestion}
							onSearch={handleSubmit}
							onCancelResearch={onCancelResearch}
							onChange={handleChange}
						/>
						<PopularSearchesHome
							onSearch={handleQuestionClick}
							randomQuestions={
								queryType === IQueryType.FUNDRAISINNG
									? FundRaisingHomeQuestions
									: MarketingHomeQuestions
							}
						/>
					</Box>
				</ContentWrapperHome>
			)}
		</Container>
	);
};
const FormControlWrapper = styled(FormControl)(({ theme }) => ({
	width: "130px",
	border: "none",
	[theme.breakpoints.down("lg")]: {
		width: "37%",
	},
	[theme.breakpoints.down("sm")]: {
		width: "37%",
	},
	"@media (max-width: 400px)": {
		width: "41%",
	},
}));
const SearchFormWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "start",
	gap: "8px",
	height: "40px",
	marginLeft: "124px",
	marginTop: "24px",
	marginBottom: "24px",
	[theme.breakpoints.down("lg")]: {
		flexDirection: "row",
		alignItems: "flex-start",
		height: "40px",
		margin: "16px 32px",
	},
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
		alignItems: "flex-start",
		height: "90px",
		margin: "16px 32px",
	},
}));
const BodyWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	width: "100%",
	padding: "0 124px",
	[theme.breakpoints.down("lg")]: {
		padding: "0 32px",
	},
	[theme.breakpoints.down("sm")]: {
		padding: "0 32px",
	},
}));
const ResultItemWrapper = styled(Box)(() => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	gap: "40px",
	[theme.breakpoints.down("lg")]: {
		gap: "24px",
	},
	[theme.breakpoints.down("sm")]: {
		gap: "24px",
	},
}));
const Container = styled(Box)(() => ({
	backgroundColor: "#fff",
	display: "flex",
	padding: 0,
	margin: 0,
	// paddingTop: 16,
	// paddingLeft: 124,
	flexDirection: "column",
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
	alignSelf: "start",
	marginTop: "24px",
	marginBottom: "54px",
	width: "100%",
	// maxWidth: 1114,
	display: "flex",
	gap: "124px",
	[theme.breakpoints.down("lg")]: {
		flexDirection: "column-reverse",
		gap: "24px",
		maxWidth: "100%",
	},
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column-reverse",
		gap: "24px",
		maxWidth: "100%",
	},
}));

const ResultsColumn = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	lineHeight: "normal",
	width: "100%",
	maxWidth: "618px",
	marginLeft: 0,
	[theme.breakpoints.down("lg")]: {
		width: "100%",
		maxWidth: "100%",
	},
	[theme.breakpoints.down("sm")]: {
		width: "100%",
		maxWidth: "100%",
	},
}));

const ResultCount = styled(Typography)(({ theme }) => ({
	color: "#7a7c80",
	fontWeight: 500,
	fontSize: "14px",
	marginBottom: "48px",
	fontFamily: "Inter",
	[theme.breakpoints.down("lg")]: {
		maxWidth: "100%",
		marginBottom: "24px",
	},
	[theme.breakpoints.down("sm")]: {
		maxWidth: "100%",
		marginBottom: "24px",
	},
}));

// const ContainerHome = styled(Box)(() => ({
// 	backgroundColor: "#fff",
// 	display: "flex",
// 	padding: 0,
// 	margin: 0,
// 	flexDirection: "column",
// }));

const ContentWrapperHome = styled(Box)(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	borderTop: "1px solid #D3D9E3",
	[theme.breakpoints.down("lg")]: {
		flexDirection: "column",
		maxWidth: "100%",
	},
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
		maxWidth: "100%",
	},
}));

export default SearchResults;
