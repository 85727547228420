import { createStore, persist } from 'easy-peasy'
import AuthModel from './models/authModel';
import DatasetModel from './models/datasetModel';
import ChatHistoryModel from './models/chatHistoryModel';
import FeedbackModel from './models/feedbackModel';
import FaqModel from './models/faqModel';
import QuestionModel from './models/questionModel';
import UserSubscriptionInfoModel from './models/subscriptionModel';

export const store = createStore({
  auth: persist(AuthModel),
  dataset: persist(DatasetModel),
  chatHistory: persist(ChatHistoryModel),
  feedback: persist(FeedbackModel),
  faq: persist(FaqModel),
  question: persist(QuestionModel),
  userSubscriptionInfo: persist(UserSubscriptionInfoModel),
});

export default store;