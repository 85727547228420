import { useStoreActions, useStoreState } from "easy-peasy";
import { Route, Routes } from "react-router-dom";
import "./App.css";
// import Layout from "./layout";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
// import Home from "./pages/HomePage";
import PageNotFound from "./pages/PageNotFound";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import { getFromLocalStorage } from "./utils";
import { AUTH_USER_INFO } from "./utils/constants";
import Pricing from "./pages/Pricing";
// import PrivayPolicy from "./pages/PrivayPolicy";
// import TermsOfServices from "./pages/TermsOfServices";
import ResetNewPasswordPage from "./pages/ResetNewPasswordPage";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
// import { useMediaQuery } from "@mui/material";
// import { theme } from "./theme";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import useDataset from "./hooks/useDataset";
import SearchResults from "./components/new-search-result/SearchResults";
import NewHomePage from "./components/new-home/NewHomePage";
const PublicRoute = (): any => {
	const reload = () => window.location.reload();

	return (
		<Routes>
			<Route path="/signup" element={<SignUpPage />} />
			<Route path="/verify-email" element={<VerifyEmailPage />} />
			<Route path="/forgot-password" element={<ForgotPasswordPage />} />
			<Route path="/reset-password" element={<ResetPasswordPage />} />
			<Route
				path="/set-new-password"
				element={<ResetNewPasswordPage />}
			/>
			<Route path="/" element={<SignInPage />} />
			<Route path="/privacypolicy.html" element={<>{reload}</>} />
			<Route path="/termsofService.html" element={<>{reload}</>} />

			<Route path="*" element={<PageNotFound />} />
		</Routes>
	);
};

const PrivateRoute = (): any => {
	return (
		<Routes>
			{/* <Route path="/" element={<Layout />}>
				<Route index element={<Home />} />
			</Route> */}
			<Route path="/" element={<SearchResults totalResults={1} />}>
				<Route index element={<SearchResults totalResults={1} />} />
			</Route>
			<Route path="/test" element={<NewHomePage />} />
			<Route path="/signup" element={<SignUpPage />} />
			<Route path="/pricing" element={<Pricing />} />
			<Route path="*" element={<PageNotFound />} />
		</Routes>
	);
};

function App() {
	const { setMaxSearchLimit, clearDataset, getThreadId } = useDataset();
	const isAuthenticated = useStoreState(
		(state: any) => state.auth.isAuthenticated
	);
	const handleLogin = useStoreActions((actions: any) => actions.auth.login);
	// URL redirection logic
	useEffect(() => {
		if (window.location.hostname === "app.shome-ai.com") {
			window.location.replace("https://app.shome.how");
		}
	}, []);
	useEffect(() => {
		const authUserInfo = getFromLocalStorage(AUTH_USER_INFO);
		if (authUserInfo) {
			handleLogin({
				userEmail: authUserInfo.userEmail,
				userName: authUserInfo.userName,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// (async function () {
	// 	const authUserInfo = getFromLocalStorage(AUTH_USER_INFO);
	// 	if (authUserInfo) {
	// 		handleLogin({
	// 			userEmail: authUserInfo.userEmail,
	// 			userName: authUserInfo.userName,
	// 		});
	// 	}
	// })();
	const [width, setWidth] = useState<number>(window.innerWidth);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);
	useEffect(() => {
		if (width <= 500) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [width]);
	// const isMobile = width <= 500;
	useEffect(() => {
		window.history.pushState({}, "", "/");

		window.onpopstate = (e: any) => {
			console.log("onpopstate", e);
			// alert("onpopstate");
			e.preventDefault();
			setMaxSearchLimit(false);

			clearDataset();
			getThreadId();
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
			return false;
		};
		return () => {
			window.onpopstate = null;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// console.log("isMobile", isMobile);
	return (
		<>
			{isAuthenticated ? (
				// isMobile ? (
				// 	<Box
				// 		sx={{
				// 			height: "100vh",
				// 			display: "flex",
				// 			justifyContent: "center",
				// 			alignItems: "center",
				// 			flexDirection: "column",
				// 		}}
				// 	>
				// 		<Typography
				// 			sx={{
				// 				fontSize: "24px",
				// 				fontWeight: "bold",
				// 				color: "#447ffc",
				// 				padding: "16px",
				// 				textAlign: "center",
				// 				lineHeight: "32px",
				// 			}}
				// 		>
				// 			Thanks for visiting our app !
				// 		</Typography>
				// 		<Typography
				// 			sx={{
				// 				fontSize: "16px",
				// 				fontWeight: "400",
				// 				color: "#515151",
				// 				padding: "16px",
				// 				textAlign: "center",
				// 				lineHeight: "24px",
				// 			}}
				// 		>
				// 			We don&apos;t support mobile right now, so check us
				// 			out on your desktop when you can.
				// 		</Typography>

				// 		<SentimentSatisfiedAltIcon
				// 			sx={{
				// 				fontSize: "32px",
				// 				color: "#447ffc",
				// 				margin: "0 8px",
				// 			}}
				// 		/>
				// 	</Box>
				// ) : (
				// 	<PrivateRoute />
				// )
				<PrivateRoute />
			) : (
				<PublicRoute />
			)}
		</>
	);
}

export default App;
