import { action } from 'easy-peasy';

export type UserInfo = {
  id: number;
  userType: string;
  congnitoUserId: string;
  firstName: string;
  email: string;
  subscriptionType: string;
  stripeCustomerId: string;
};

export interface UserSubscriptionPrices {
  starter: string;
  super: string;
}
export interface UserSubscriptionModel {
  user: UserInfo;
  prices: UserSubscriptionPrices;
  starterUrl: string;
  superUrl: string;
  isLoading: boolean;
  setUser: Function | any;
  setPrices: Function | any;
  setLoading: Function | any;
  setStarterUrl: Function | any;
  setSuperUrl: Function | any;
  clearUser: Function | any;
}

const UserSubscriptionInfoModel: UserSubscriptionModel = {
  user: {
    id: 0,
    userType: "",
    congnitoUserId: "",
    firstName: "",
    email: "",
    subscriptionType: "",
    stripeCustomerId: "",
  },
  prices: {
    starter: "",
    super: "",
  },
  starterUrl: "",
  superUrl: "",
  isLoading: false,
  setLoading: action((state: any, payload: any) => {
    state.isLoading = payload;
  }),
  setUser: action((state: any, payload: UserInfo) => {
    state.user = payload;
    state.isLoading = false;
  }),
  setPrices: action((state: any, payload: UserSubscriptionPrices) => {
    state.prices = payload;
    state.isLoading = false;
  }),
  setStarterUrl: action((state: any, payload: string) => {
    state.starterUrl = payload;
    state.isLoading = false;
  }),
  setSuperUrl: action((state: any, payload: string) => {
    state.superUrl = payload;
    state.isLoading = false;
  }),
  clearUser: action((state: any) => {
    state.user = {
      id: 0,
      userType: "",
      congnitoUserId: "",
      firstName: "",
      email: "",
      subscriptionType: "",
      stripeCustomerId: "",
    };
    state.isLoading = false;
  }),
};


export default UserSubscriptionInfoModel;
// Define the user model
// export interface IQuestionModel {
//   data: Array<string>
//   isLoading: boolean
//   setData: Function | any
//   setLoading: Function | any
//   clearData: Function | any
// }

// const QuestionModel: IQuestionModel = {
//   data: [],
//   isLoading: false,
//   setLoading: action((state: any, payload: any) => {
//     state.isLoading = payload
//   }),
//   setData: action((state: any, payload: ISetData) => {
//     state.data = payload.questions
//     state.isLoading = false
//   }),
//   clearData: action((state: any) => {
//     state.data = [];
//     state.isLoading = false
//   }),
// };

// export default QuestionModel