export const UPLOAD_FILE_MODAL = "UPLOAD_FILE_MODAL";
export const PAGE_LIMIT = "12";
export const APP_NAME = "SHOME_AI";
export const USER_INFO = "USER_INFO";
export const NEW_USER_INFO = "NEW_USER_INFO";
export const FORGOT_EMAIL = "FORGOT_EMAIL";
export const RESET_NEW_PASS_OBJ = "RESET_NEW_PASS_OBJ";
export const RESET_NEW_PASS_EMAIL = "RESET_NEW_PASS_EMAIL";
export const RESET_NEW_OLD_PASS = "RESET_NEW_OLD_PASS";
export const AUTH_TMP_MSG = "AUTH_TMP_MSG";
export const AUTH_USER_INFO = "AUTH_USER_INFO";

export const REGISTERED_AUCTION = "registeredAuction";
export const SAVE_FCM_TOKEN_INTO_DB = "SAVE_FCM_TOKEN_INTO_DB";
export const DELIVERY_TYPE = "DELIVERY_TYPE";
export const SHIPMENT_ITEMS = "SHIPMENT_ITEMS";
export const enum DeliveryOptions {
  Yes_Pickup_Everything = "pickup_yes",
  Pickup_All_Together = "pickup_all",
  Delivery_All = "delivery_all",
  Delivery_Some = "delivery_some",
  Some_Split = "some_split",
  Shipping = "shipping",
  PickUp = "pickup",
}
export const PREVIOUS_PAGE = "PREVIOUS_PAGE";
export const PRIVATE_AUCTION_ID = "PRIVATE_AUCTION_ID";
export const GRID_VIEW = "GRID_VIEW";
export const LIST_VIEW = "LIST_VIEW";
export const USER_PREFERRED_VIEW = "USER_PREFERRED_VIEW";
export const HISTORY_STACK = "HISTORY_STACK";

export const POPULAR_QUESTIONS = [
  "Help me be a better storyteller during pitches",
  "How to get intros to investors?",
  "Pitch deck examples",
  "Investor CRM examples",
  "How to set my valuation?",
  "Typical pre-seed valuations 2023",
  "Improve my pitch deck design",
  "Tips for pitching",
  "Elevator pitch templates",
  "How big does my market need to be?",
  "Calculating TAM for multiple customer segments",
  "Difference between SAFE and convertible note",
  "Example 'Ask' slides in a pitch deck",
  "What to put in a startup financial model?",
  "Do I need a data room?",
  "How much to raise?",
  "Example followup emails to send investors",
  "Tactics for raising crowdfunding",
  "Example forwardable emails",
  "Watch real intro pitch calls to VCs",
  "Calculating dilution on SAFEs",
  "How to figure out if investors are a good fit?",
  "How to build your investor list?",
  "Common fundraising mistakes",
  "Step-by-step guide to raising pre-seed funding",
  "How many investors should I meet with?",
  "What months are good for fundraising?",
  "What are VCs looking for in a pitch?",
  "How to raise from angel investors?",
  "Stories of real founders fundraises",
  "Should I take small checks?",
  "How to raise in tranches?",
  "What's a teaser deck?",
  "Pitch deck templates",
  "How to create a great pitch deck?",
  "Investor updates from other founders"
];

export const MARKETING_QUESTIONS = [
  "How to build your startup's brand?",
  "How to get your first 100 users?",
  "How to scale a TikTok channel",
  "User acquisition channels",
  "How to optimize a marketing funnel?",
  "Guide to building in public",
  "How to launch on product hunt?",
  "How to get beta users for a product?",
  "Example cold sales emails",
  "Getting cold outreach email to work for you",
  "Writing blog posts for SEO",
  "How influencer marketing works",
  "How to find influencers to market your product",
  "Examples of how startups got their first 100 users",
  "How to launch a marketplace",
  "When to start investing in SEO?",
  "How to warm up my domain",
  "Sending 5000 + emails per day",
  "Should I have a free trial?",
  "When should I hire a marketer?",
  "What signup and conversion rates are normal",
  "Startup landing page template",
  "Should I do PLG or SLG?",
  "B2B sales playbook",
  "B2B branding",
  "How to start a newsletter?"
];

export const FundRaisingHomeQuestions = [
  "How to raise from angel investors?",
  "Typical pre-seed valuations 2023",
  "Difference between SAFE and convertible note",
];

export const MarketingHomeQuestions = [
  "How does SEO work?",
  "How to get your first users?",
  "How to launch on product hunt?",
];