import { Typography, useTheme } from "@mui/material";
import React, { FC } from "react";

interface FormTitle {
	title: string;
}

export const FormTitle: FC<FormTitle> = ({ title }) => {
	const theme = useTheme();
	return (
		<Typography
			// mb={30}
			fontFamily={"Inter"}
			marginBottom={"30px"}
			fontWeight={"700"}
			fontSize={"36px"}
			color={"#4D4E53"}
			lineHeight={"40px"}
			letterSpacing={"-0.02em"}
		>
			{title}
		</Typography>
	);
};

export default FormTitle;
