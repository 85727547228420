import { CircularProgress } from "@mui/material";
import { useFormikContext } from "formik";
import { FC } from "react";
import PrimaryButton from "../buttons/PrimaryButton";

interface IFormButton {
	label: string;
	loading?: boolean;
}

export const FormButton: FC<IFormButton> = ({
	label = "Submit",
	loading = false,
}) => {
	const { isSubmitting, isValid } = useFormikContext<any>();
	return (
		<PrimaryButton
			icon={
				isSubmitting || loading ? (
					<CircularProgress size={20} color="inherit" />
				) : (
					""
				)
			}
			disableElevation
			disabled={isSubmitting || !isValid || loading}
			type="submit"
			fullWidth
			size="small"
			text={isSubmitting || loading ? "" : label}
			style={{
				height: "40px",
				marginTop: "32px", //label === "Log in" ? "32px" : "32px",
				marginBottom: "30px",
				fontSize: "16px",
				fontWeight: 600,
				lineHeight: "30px",
				color: "#ffffff",
				fontFamily: "Inter",
			}}
		/>
	);
};
