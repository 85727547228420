import { Box, styled } from "@mui/material";
import { FC } from "react";

interface IFormWrapper {
	children: React.ReactNode;
}
const FormWrapper: FC<IFormWrapper> = ({ children }) => {
	const BoxWrapper = styled(Box)(({ theme }) => ({
		width: "100%",
		borderRadius: "20px",
		// background: "#fff",
		transition: "0.3s all ease-in-out",
		// minWidth: "500px",
		boxSizing: "border-box",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		[theme.breakpoints.down("md")]: {
			// padding: "30px 60px",
			minWidth: "430px",
		},
		[theme.breakpoints.down("sm")]: {
			// padding: "30px 30px",
			minWidth: "300px",
		},
	}));
	const Wrapper = styled(Box)(({ theme }) => ({
		// maxWidth: "500px",
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		flexDirection: "column",
		// justifyContent: "center",
		// alignItems: "center",
		// gap: "12px",
	}));
	return (
		<BoxWrapper>
			<Wrapper>{children}</Wrapper>
		</BoxWrapper>
	);
};

export default FormWrapper;
