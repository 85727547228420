import CloseIcon from "@mui/icons-material/Close";
import { Alert, Box, Grow, IconButton, Typography } from "@mui/material";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { IError } from "../../hooks/useAuth";
import { theme } from "../../theme";

interface IFormAlert {
  error: IError | null;
  setError?: any;
  containerStyle?: any;
  disableAlertButton?: boolean;
}
export const FormAlert: FC<IFormAlert> = ({
  error,
  setError,
  containerStyle,
  disableAlertButton,
}) => {
  const [open, setOpen] = useState(true);
  if (!error || !open) {
    return null;
  }

  return (
    <Box
      sx={[
        {
          display: "flex",
          mb: 4,
        },
        { ...containerStyle },
      ]}
    >
      <Grow in={open} style={{ width: "100%" }}>
        <Alert
          sx={{
            border: '1px solid #C9E2FF'
          }}
          severity={error?.severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
                if (typeof setError === "function") {
                  setError(null);
                }
              }}
            >
              {disableAlertButton ? null : <CloseIcon fontSize="inherit" />}
            </IconButton>
          }
        >
          <Typography
            sx={{
              color: theme.palette.secondary.light,
            }}
          >
            {error?.message}
            {error?.pageLabel && error?.redirectUrl && (
              <Typography
                sx={{
                  textDecoration: "none",
                  display: "flex",
                  fontSize: "inherit",
                  lineHeight: "inherit",
                  fontWeight: "inherit",
                  color: "inherit",
                }}
              >
                Please verify your email from this
                <Link
                  to={error?.redirectUrl}
                  style={{
                    display: "inline-flex",
                    margin: "0 0 0 4px",
                    textDecoration: "underline",
                    color: "inherit",
                    fontWeight: "500",
                    fontSize: "inherit",
                    lineHeight: "inherit",
                  }}
                >
                  link
                </Link>
                .
              </Typography>
            )}
          </Typography>
        </Alert>
      </Grow>
    </Box>
  );
};
