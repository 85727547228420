import { action } from 'easy-peasy';
import { FaqResponse } from '../../openapi';

// Define the user model
export interface IFaqModel {
  data: undefined | Array<FaqResponse>
  isLoading: boolean
  setData: Function | any
  setLoading: Function | any
  clearData: Function | any
}

const FaqModel: IFaqModel = {
  isLoading: false,
  data: undefined,
  setLoading: action((state: any, payload: boolean) => {
    state.isLoading = payload
  }),
  setData: action((state: any, payload: Array<FaqResponse>) => {
    state.data = payload
    state.isLoading = false
  }),
  clearData: action((state: any) => {
    state.data = undefined
    state.isLoading = false
  }),
};
export default FaqModel