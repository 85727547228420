import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
	palette: {
		primary: {
			main: "#407BFF",
			// dark: "#407BFF",
			// light: "#647DE4",
			contrastText: "#fff",
		},
		secondary: {
			main: "#141518",
			dark: "#050506",
			light: "#2C2D30",
			contrastText: "#fff",
		},
		error: {
			main: "#F2323F",
			light: "#FA3F48",
			dark: "#D92935",
			contrastText: "#fff",
		},
		success: {
			main: "#3DBA4E",
			light: "#47C257",
			dark: "#299E39",
			contrastText: "#fff",
		},
		warning: {
			main: "#FCB017",
			light: "#FFBC21",
			dark: "#CF9216",
			contrastText: "#fff",
		},
		info: {
			main: "#23BA99",
			light: "#2EC39F",
			dark: "#16987C",
			contrastText: "#fff",
		},
		text: {
			primary: "#141518",
			secondary: "#fff",
		},
		background: {
			default: "#fff",
		},
		divider: "#f5f5f5",
	},
	shape: {
		borderRadius: 8,
	},
	typography: {
		fontFamily: "Inter",
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		h1: {
			fontFamily: "Inter",
			fontSize: "42px",
			fontWeight: 500,
			lineHeight: "48px",
		},
		h2: {
			fontFamily: "Inter",
			fontSize: "35px",
			fontWeight: 500,
			lineHeight: "40px",
		},
		h3: {
			fontFamily: "Inter",
			fontSize: "31px",
			fontWeight: 500,
			lineHeight: "36px",
		},
		h4: {
			fontFamily: "Inter",
			fontSize: "28px",
			fontWeight: 500,
			lineHeight: "32px",
		},
		h5: {
			fontFamily: "Inter",
			fontSize: "24px",
			fontWeight: 500,
			lineHeight: "28px",
		},
		h6: {
			fontFamily: "Inter",
			fontSize: "21px",
			fontWeight: 500,
			lineHeight: "24px",
		},
		subtitle1: {
			fontFamily: "Inter",
			fontSize: "21px",
			fontWeight: 400,
			lineHeight: "25px",
		},
		subtitle2: {
			fontFamily: "Inter",
			fontSize: "17px",
			fontWeight: 400,
			lineHeight: "24px",
		},
		body1: {
			fontFamily: "Inter",
			fontSize: "16px",
			fontWeight: 400,
			lineHeight: "20px",
		},
		body2: {
			fontFamily: "Inter",
			fontSize: "14px",
			fontWeight: 400,
			lineHeight: "22px",
		},
		button: {
			fontFamily: "Inter",
		},
	},
	components: {
		MuiPaper: {
			styleOverrides: {
				root: {
					"@media (max-width:900px)": {
						width: "100%",
						height: "100%",
						top: "0 !important",
						left: "0 !important",
						right: "0 !important",
						bottom: "0 !important",
						borderRadius: "0 !important",
						maxWidth: "1440px",
						maxHeight: "1024px",
					},
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					width: "100%",
				},
			},
		},

		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: "8px",
					"& .MuiOutlinedInput-input.MuiInputBase-inputSizeSmall": {
						height: "27px",
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: "8px",
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					borderRadius: "4px",
					fontSize: "14px",
					letterSpacing: "0.2px",
					fontWeight: 400,
					"@media (max-width:600px)": {
						fontSize: "12px",
					},
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					backgroundColor: "#fff",
					border: "1.2px solid #91B7B9",
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					backgroundColor: "#FAFAFC",
					// width: 272,
					// top: "65px",
					// marginLeft: "20px",
					paddingTop: "65px",
					zIndex: 1,
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					// boxShadow: "1px 1px 2px 2px #20846A inset",
				},
			},
		},
		MuiMobileStepper: {
			styleOverrides: {
				dotActive: {
					backgroundColor: "#3D6FD1",
					opacity: "1",
					width: "6px",
					height: "6px",
				},
				dots: {
					display: "flex",
					justifyContent: "center",
					allignItems: "center",
				},
				dot: {
					backgroundColor: "#1472FF",
					opacity: "0.3",
					width: "4px",
					height: "4px",
					alignSelf: "center",
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				select: {
					lineHeight: "1.9em",
					"&:before": {
						borderColor: "##C3C6D6",
					},
					"&:after": {
						borderColor: "##C3C6D6",
					},
					"&:hover": {
						borderColor: "##C3C6D6",
					},
					"&:focused": {
						borderColor: "#C3C6D6",
					},
				},
				root: {
					borderColor: "#C3C6D6",
				},
				// icon: {
				// 	color: "#407BFF",
				// },
				// select: {
				// 	// color: "#407BFF",
				// 	lineHeight: "1.6em",
				// 	// "&:before": {
				// 	// 	borderColor: "#407BFF",
				// 	// },
				// 	// "&:after": {
				// 	// 	borderColor: "#407BFF",
				// 	// },
				// 	// "&:hover": {
				// 	// 	borderColor: "#407BFF",
				// 	// },
				// 	// "&:focused": {
				// 	// 	borderColor: "#407BFF",
				// 	// },
				// },
				outlined: {
					borderColor: "#407BFF",
				},
			},
		},
	},
});
