import { Box, Grid, Typography, styled } from "@mui/material";
import { FC, useEffect, useState } from "react";
import NewAuthLogo from "../../svg-components/NewAuthLogo";

interface IPageWrapper {
	children: React.ReactNode;
}
const PageWrapper: FC<IPageWrapper> = ({ children }) => {
	const path = window.location.pathname;

	const [widthx, setWidth] = useState<number>(window.innerWidth);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);
	useEffect(() => {
		if (widthx <= 850) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [widthx]);
	// const isMobile = widthx <= 850;
	const Wrapper = styled(Box)(({ theme }) => ({
		overflow: "hidden",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		borderRadius: "8px",
		border: "1px solid #E5E5E0",
		boxShadow:
			"0px 0px 0px 1px rgba(0, 0, 0, 0.07), 0px 1.5px 1.5px 0px rgba(255, 255, 255, 0.20) inset, 0px -1.5px 1.5px 0px rgba(0, 0, 0, 0.10) inset, 0px 0px 22px 0px rgba(0, 0, 0, 0.10)",
		background: "#fff",
		gap: "30px",
		padding: "16px",
		margin: "10px",
	}));
	return (
		<Grid
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				alignItems: "center",
				boxSizing: "border-box",
				minHeight: "100vh",
				// gap: "30px",
				// padding: "16px",
			}}
		>
			<Wrapper>
				{!isMobile && (
					<Box
						sx={{
							// padding: "16px",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							border: "transparent",
							borderRadius: "8px",
						}}
					>
						<Box
							sx={{
								// width: "100%",
								// height: "95%",
								width: "333px",
								// background: "#141718",
								backgroundImage:
									"url('logoauthbackground.svg')",
								backgroundRepeat: "no-repeat",
								backgroundSize: "contain",
								height: "529px",
								// minHeight: "100vh",
								// padding: "45px",
								padding: "136px 0px 40px 40px",
								// margin: "16px 0 16px 16px",
								display: "flex",
								flexDirection: "column",
								// justifyContent: "center",
								alignItems: "flex-start",
								gap: "20px",
							}}
						>
							{/* <Box> */}
							{/* <Box
							component="img"
							alt="Logo."
							src="newLogo.svg"
							/> */}
							{/* <FormLogo /> */}
							{/* <Logo customStyle={{ color: "#fff" }} /> */}
							{/* </Box> */}
							<NewAuthLogo />
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "flex-start",
								}}
							>
								{/* <FormHeader
								title="Welcome back!"
								subTitle="Securely sign in to manage your account and access exclusive features"
							/> */}

								<Typography
									variant="h4"
									fontWeight={"700"}
									fontSize={"36px"}
									lineHeight={"40px"}
									letterSpacing={"-2%"}
									mb={3}
									color={"#fff"}
									textAlign={"left"}
								>
									{path !== "/signup"
										? "Welcome back!"
										: "Welcome!"}
								</Typography>
								<Typography
									variant="body2"
									fontSize={"16px"}
									letterSpacing={"-2%"}
									lineHeight={1.5}
									// sx={{ whiteSpace: "pre-wrap" }}
									color={"#E8ECEF"}
								>
									{path !== "/signup"
										? "Sign in to start searching and "
										: "Sign up to start searching and "}
								</Typography>
								<Typography
									variant="body2"
									fontSize={"16px"}
									letterSpacing={"-2%"}
									lineHeight={1.5}
									// sx={{ whiteSpace: "pre-wrap" }}
									color={"#E8ECEF"}
								>
									{"manage your account"}
								</Typography>
							</Box>
						</Box>
					</Box>
				)}
				<Box
					display={"flex"}
					flexDirection={"row"}
					justifyContent={"space-evenly"}
					alignItems={"center"}
					width={"448px"}
					height={"529px"}
					padding={"0px 46px 0px 30px"}
				>
					{children}
				</Box>
			</Wrapper>
		</Grid>
	);
};

export default PageWrapper;
