import {
	Box,
	// FormControl,
	// InputLabel,
	// MenuItem,
	// Select,
	// SelectChangeEvent,
	Stack,
	// Typography,
	styled,
} from "@mui/material";
import React from "react";
// import ChatHistory from './ChatHistory';
import LogoSmall from "../svg-components/LogoSmall";
import UserAuth from "./UserAuth";
import useAuth from "../hooks/useAuth";
import useDataset from "../hooks/useDataset";
import { useNavigate } from "react-router-dom";
// import { IQueryType } from "../redux/models/datasetModel";

// const CustomSelect = styled(Select)(() => ({
// 	width: 300,
// 	"&.MuiOutlinedInput-root": {
// 		"& fieldset": {
// 			borderColor: "#407BFF",
// 		},
// 		"&:hover fieldset": {
// 			borderColor: "#407BFF",
// 		},
// 		"&.Mui-focused fieldset": {
// 			borderColor: "#407BFF",
// 		},
// 	},
// }));
const Header = () => {
	const { userInfo, getUserInfo } = useAuth();

	const {
		setMaxSearchLimit,
		clearDataset,
		getThreadId,
		// queryType,
		// setQuryType,
	} = useDataset();
	// const handleChange = (event: SelectChangeEvent) => {
	// 	setQuryType(event.target.value as IQueryType);
	// };
	React.useEffect(() => {
		if (!userInfo) getUserInfo();
	}, [getUserInfo, userInfo]);
	const navigate = useNavigate();
	const handleNewQuestion = () => {
		setMaxSearchLimit(false);

		clearDataset();
		getThreadId();
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
		navigate("/");
	};
	return (
		<HeaderAllWrapper
			// position={"sticky"}
			top={"0px"}
		>
			<HeaderWrapper width={"100%"}>
				<Box
					display={"flex"}
					alignItems={"center"}
					onClick={handleNewQuestion}
					sx={{
						cursor: "pointer",
					}}
				>
					<LogoSmall />
				</Box>
				<Box>
					<Stack direction="row" spacing={4} alignItems={"center"}>
						{/* <Box
							sx={{
								display: "flex",
								alignItems: "center",
								gap: "8px",
							}}
						>
							<Typography
								fontSize={"14px"}
								fontFamily={"Inter"}
								color={"#515151"}
								fontWeight={"500"}
							>
								{userInfo?.subscriptionType}
							</Typography>
						</Box> */}
						<UserAuth />
					</Stack>
				</Box>
			</HeaderWrapper>
		</HeaderAllWrapper>
	);
};

export default Header;

const HeaderAllWrapper = styled(Box)(() => ({
	background: "#fff",
	padding: "10px 32px",
	zIndex: "100",
	height: "64px",
}));
const HeaderWrapper = styled(Box)(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
}));
