import { Box, Skeleton } from "@mui/material";
import React from "react";

const CustomLoader = () => {
	return (
		<Box sx={{ width: "100%" }} minHeight={"200px"}>
			<Skeleton animation="wave" />
			<Skeleton animation="pulse" />
			<Skeleton animation="wave" />
			<Skeleton animation="pulse" />
			<Skeleton animation="wave" />
			<Skeleton animation="pulse" />
			<Skeleton animation="wave" />
			<Skeleton animation="pulse" />
			<Skeleton animation="wave" />
			<Skeleton animation="pulse" />
			<Skeleton animation="wave" />
		</Box>
	);
};
export default CustomLoader;
