import { Auth } from "aws-amplify";
import { Configuration } from "../openapi";
import { BroadcastChannel } from 'broadcast-channel';

const logoutChannel = new BroadcastChannel('logout');


export default class ApiConfiguration extends Configuration {
  constructor() {
    super();
    this.basePath = process.env.REACT_APP_API_URL
      ? process.env.REACT_APP_API_URL
      : undefined;
    this.apiKey = async () => {
      try {
        const session = await Auth.currentSession();
        return session?.getAccessToken().getJwtToken();
      } catch (error: any) {
        // console.log('apiKey error=', error)
        // Special case when string is returned (for example from cognito sometimes returns string for error)
        if (typeof error === "string") {
          const errorObj: any = new Error(error);
          errorObj.sentry = true;
          if (error === "No current user") {
            try {
              const currentUser = await Auth.currentAuthenticatedUser();
              const token = currentUser.token
              // console.log('apiKey token=', token)
              return token;
            } catch (error: any) {
              logoutChannel.postMessage("Logout")

              errorObj.sentry = false;
            }
            throw errorObj;
          }
        }
        const { message } = error;
        error.message = `Auth error happened in serviceConfig.apiKey ${message}`;
        error.sentry = true;
        throw error;
      }
    };
  }
}
