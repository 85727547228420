import {
  UsersApi,
  DatasetsApi,
  TagsApi,
  ChatHistoryApi,
  CreateUsersApi,
  FaqApi,
  SubscriptionsApi
} from "../openapi";
import ApiConfiguration from "./apiConfiguration";
import PublicApiConfiguration from "./publicApiConfiguration";

const getServiceConfig = () => {
  const serviceConfiguration: ApiConfiguration = new ApiConfiguration();
  return serviceConfiguration;
};

const getPublicServiceConfig = () => {
  const serviceConfiguration: ApiConfiguration = new PublicApiConfiguration();
  return serviceConfiguration;
};

let userApi: UsersApi;
let datasetsApi: DatasetsApi;
let tagsApi: TagsApi;
let chatHistoryApi: ChatHistoryApi;
let createUser: CreateUsersApi;
let faqApi: FaqApi;
let subscriptionApi: SubscriptionsApi;


export const getUserService = () => {
  userApi = new UsersApi(getServiceConfig());
  return userApi;
};
export const getCreateUserService = () => {
  createUser = new CreateUsersApi(getPublicServiceConfig());
  return createUser;
};
export const getDatasetService = async () => {
  datasetsApi = new DatasetsApi(getServiceConfig());
  return datasetsApi;
};
export const getTagService = async () => {
  tagsApi = new TagsApi(getServiceConfig());
  return tagsApi;
};
export const getChatHistoryService = async () => {
  chatHistoryApi = new ChatHistoryApi(getServiceConfig());
  return chatHistoryApi;
};
export const getFaqService = async () => {
  faqApi = new FaqApi(getServiceConfig());
  return faqApi;
};

export const getSubscriptionService = async () => {
  subscriptionApi = new SubscriptionsApi(getServiceConfig());
  return subscriptionApi;
};
