import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowTrendingUp from "../../svg-components/ArrowTrendingUp";
import CustomSearchIcon from "../../svg-components/SearchIcon";
import useDataset from "../../hooks/useDataset";

const PopularSearches = ({
	onSearch,
}: {
	onSearch: (value: string) => void;
}) => {
	const { randomQuestions } = useDataset();
	const handleQuestionClick = (e: any) => {
		e.preventDefault();
		if (onSearch) {
			onSearch(e.target.innerText);
		}
	};
	return (
		<PopularSearchesContainer>
			<PopularSearchesHeader>
				{/* <PopularIcon
					src="https://cdn.builder.io/api/v1/image/assets/TEMP/909f461f57c2541116b1494c59e86128efdc1ced3f1821f975b2b2a7d1a05f01?apiKey=c76115b1b1bd487eb22c081a0aa154d8&&apiKey=c76115b1b1bd487eb22c081a0aa154d8"
					alt=""
				/> */}
				<ArrowTrendingUp />
				<PopularTitle>Popular searches</PopularTitle>
			</PopularSearchesHeader>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "start",
					gap: "12px",
					width: "100%",
				}}
			>
				{randomQuestions.map((search: string, index: number) => (
					<PopularSearchItem
						key={index}
						onClick={handleQuestionClick}
					>
						{/* <SearchIcon src={search.icon} alt="" /> */}
						<CustomSearchIcon />
						<SearchText>{search}</SearchText>
					</PopularSearchItem>
				))}
			</Box>
		</PopularSearchesContainer>
	);
};

const PopularSearchesContainer = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	fontSize: 14,
	color: "#515151",
	fontWeight: 500,
	textAlign: "center",
	width: "100%",
	maxWidth: "390px", //372 in figma
	gap: "16px",
	// marginLeft: 20,
	[theme.breakpoints.down("lg")]: {
		width: "100%",
	},
	[theme.breakpoints.down("sm")]: {
		width: "100%",
		// marginTop: 40,
		// marginLeft: 0,
	},
}));

const PopularSearchesHeader = styled(Box)({
	alignSelf: "start",
	display: "flex",
	gap: 8,
	fontWeight: 600,
	// padding: "0 20px",
});

const PopularTitle = styled(Typography)({
	fontFamily: "Inter",
	fontSize: "14px",
	color: "#515151",
	fontWeight: 600,
	lineHeight: "16.94px",
	textAlign: "center",
});

const PopularSearchItem = styled(Box)({
	borderRadius: 56,
	display: "flex",
	// marginTop: 12,
	gap: 10,
	lineHeight: "24px",
	padding: 12,
	border: "1px solid #dee3ed",
	cursor: "pointer",
	width: "100%",
	"&:hover": {
		backgroundColor: "#F5F7FC",
	},
	// "&:first-of-type": {
	// 	marginTop: 16,
	// },
});

const SearchText = styled(Typography)(({ theme }) => ({
	fontFamily: "Inter",
	fontSize: "14px",
	fontWeight: 500,
	lineHeight: "24px",
	color: "#515151",

	[theme.breakpoints.down("sm")]: {
		fontSize: "12px",
	},
}));

export default PopularSearches;
