import React, { useState } from "react";
import Box from "@mui/material/Box";
import Header from "../../layout/Header";
import { styled } from "@mui/system";
import useDataset from "../../hooks/useDataset";
import TopHeading from "../../pages/home/TopHeading";

const ContainerHome = styled(Box)(() => ({
	backgroundColor: "#fff",
	display: "flex",
	padding: 0,
	margin: 0,
	flexDirection: "column",
}));

const ContentWrapperHome = styled(Box)(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	borderTop: "1px solid #D3D9E3",
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		maxWidth: "100%",
	},
}));

const NewHomePage = () => {
	const { datasets } = useDataset();

	return (
		<ContainerHome>
			<Header />
			<hr style={{ border: "1px solid #D3D9E3" }} />
			<ContentWrapperHome>
				{(!datasets || datasets?.length < 1) && (
					<Box>
						<TopHeading />
					</Box>
				)}
			</ContentWrapperHome>
		</ContainerHome>
	);
};

export default NewHomePage;
