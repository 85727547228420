import PageWrapper from "../components/common/PageWrapper";
import ForgotPasswordForm from "../components/form/ForgotPasswordForm";

const ForgotPasswordPage = () => {

  return (
    <PageWrapper>
      <ForgotPasswordForm />
    </PageWrapper>
  );
};

export default ForgotPasswordPage;
