import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowTrendingUp from "../../svg-components/ArrowTrendingUp";
import CustomSearchIcon from "../../svg-components/SearchIcon";
const PopularSearchesHome = ({
	onSearch,
	randomQuestions,
}: {
	onSearch: (value: string) => void;
	randomQuestions: string[];
}) => {
	const handleQuestionClick = (e: any) => {
		e.preventDefault();
		if (onSearch) {
			onSearch(e.target.innerText);
		}
	};
	return (
		<PopularSearchesContainer>
			<PopularSearchesHeader>
				{/* <PopularIcon
					src="https://cdn.builder.io/api/v1/image/assets/TEMP/909f461f57c2541116b1494c59e86128efdc1ced3f1821f975b2b2a7d1a05f01?apiKey=c76115b1b1bd487eb22c081a0aa154d8&&apiKey=c76115b1b1bd487eb22c081a0aa154d8"
					alt=""
				/> */}
				<ArrowTrendingUp />
				<PopularTitle>Popular searches</PopularTitle>
			</PopularSearchesHeader>

			<PopularQuestionsContainer>
				<PopularFirstTwoQuestionsContainer>
					<PopularSearchItem onClick={handleQuestionClick}>
						<CustomSearchIcon />
						<SearchText>
							{randomQuestions.length && randomQuestions[0]}
						</SearchText>
					</PopularSearchItem>
					<PopularSearchItem onClick={handleQuestionClick}>
						<CustomSearchIcon />
						<SearchText>
							{randomQuestions.length && randomQuestions[1]
								? randomQuestions[1]
								: ""}
						</SearchText>
					</PopularSearchItem>
				</PopularFirstTwoQuestionsContainer>
				<Box
					sx={{
						width: "100%",
					}}
				>
					<PopularSearchItem onClick={handleQuestionClick}>
						<CustomSearchIcon />
						<SearchText>
							{randomQuestions.length && randomQuestions[2]
								? randomQuestions[2]
								: ""}
						</SearchText>
					</PopularSearchItem>
				</Box>
			</PopularQuestionsContainer>
		</PopularSearchesContainer>
	);
};
const PopularFirstTwoQuestionsContainer = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	width: "100%",
	gap: "16px",
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
	},
}));
const PopularQuestionsContainer = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "start",
	gap: "16px",
	minWidth: "670px",
	width: "100%",
	[theme.breakpoints.down("sm")]: {
		minWidth: "370px",
	},
}));
const PopularSearchesContainer = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	fontSize: 14,
	color: "#515151",
	fontWeight: 500,
	textAlign: "center",
	width: "100%",
	maxWidth: "370px", //372 in figma
	gap: "16px",
	marginTop: "64px",
	[theme.breakpoints.down("sm")]: {
		// width: "100%",
		marginTop: 40,
		marginLeft: 0,
		paddingBottom: 40,
	},
}));

const PopularSearchesHeader = styled(Box)({
	alignSelf: "start",
	display: "flex",
	gap: 8,
	fontWeight: 600,
	// padding: "0 20px",
});

const PopularTitle = styled(Typography)({
	fontFamily: "Inter",
	fontSize: "14px",
	color: "#515151",
	fontWeight: 600,
	lineHeight: "16.94px",
	textAlign: "center",
});

const PopularSearchItem = styled(Box)({
	borderRadius: 56,
	display: "flex",
	alignItems: "center",
	// marginTop: 12,
	gap: 10,
	lineHeight: "24px",
	padding: 12,
	border: "1px solid #dee3ed",
	cursor: "pointer",
	width: "100%",
	"&:hover": {
		backgroundColor: "#F5F7FC",
	},

	// "&:first-of-type": {
	// 	marginTop: 16,
	// },
});

const SearchText = styled(Typography)(({ theme }) => ({
	fontFamily: "Inter",
	fontSize: "14px",
	fontWeight: 500,
	lineHeight: "24px",
	color: "#515151",
	[theme.breakpoints.down("sm")]: {
		fontSize: "12px",
	},
}));
export default PopularSearchesHome;
