import { action } from 'easy-peasy';

export interface ISetData {
  questions: Array<string>
}

// Define the user model
export interface IQuestionModel {
  data: Array<string>
  isLoading: boolean
  setData: Function | any
  setLoading: Function | any
  clearData: Function | any
}

const QuestionModel: IQuestionModel = {
  data: [],
  isLoading: false,
  setLoading: action((state: any, payload: any) => {
    state.isLoading = payload
  }),
  setData: action((state: any, payload: ISetData) => {
    state.data = payload.questions
    state.isLoading = false
  }),
  clearData: action((state: any) => {
    state.data = [];
    state.isLoading = false
  }),
};

export default QuestionModel