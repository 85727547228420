import { action } from 'easy-peasy';

export type UserInfo = {
  id: number;
  userType: string;
  congnitoUserId: string;
  firstName: string;
  email: string;
  subscriptionType: string;
  stripeCustomerId: string;
};
// Define the user model
export interface IAuthModel {
  isAuthenticated: boolean;
  userName: string | null;
  userEmail: string | null;
  userInfo: UserInfo | null;
  login: Function | any
  logout: Function | any
  setUserInfo: Function | any
}

const AuthModel = {
  isAuthenticated: false,
  userName: null,
  userEmail: null,
  userInfo: null,
  login: action((state: any, payload: any) => {
    state.isAuthenticated = true;
    state.userName = payload.userName
    state.userEmail = payload.userEmail
  }),
  logout: action((state: any) => {
    state.isAuthenticated = false;
    state.userName = null
    state.userEmail = null
  }),
  setUserInfo: action((state: any, payload: UserInfo) => {
    state.userInfo = payload;
  }),
};

export default AuthModel