/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import PageWrapper from "../components/common/PageWrapper";
import SignUpForm from "../components/form/SignUpForm";
import { useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";

const SignUpPage = () => {
	const navigate = useNavigate();

	const isAuthenticated = useStoreState(
		(state: any) => state.auth.isAuthenticated
	);
	useEffect(() => {
		if (isAuthenticated) {
			navigate("/");
		}
	}, [isAuthenticated]);
	return (
		<PageWrapper>
			<SignUpForm />
		</PageWrapper>
	);
};

export default SignUpPage;
