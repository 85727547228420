import PageWrapper from "../components/common/PageWrapper";
import ResetNewPasswordForm from "../components/form/ResetNewPasswordForm";

const ResetNewPasswordPage = () => {
	return (
		<PageWrapper>
			<ResetNewPasswordForm />
		</PageWrapper>
	);
};

export default ResetNewPasswordPage;
