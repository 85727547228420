import { useSnackbar } from "notistack";

enum AlertTypes {
  Success = "success",
  Default = "default",
  Warning = "warning",
  Info = "info",
  Error = "error",
}

const useAlert = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showSnackbar = (message: string, msgType: AlertTypes | any) => {
    enqueueSnackbar(message, {
      variant: msgType ? msgType : AlertTypes.Success,
      autoHideDuration: 2000,
    });
  };
  return {
    showSnackbar,
  };
};

export default useAlert;
