import { action } from 'easy-peasy';
import { SearchResponse, WeaviateResult } from '../../openapi'


export enum IFeedbackType {
  NONE = 'none',
  POSITIVE = 'positive',
  NEGATIVE = 'negative'
}
export enum IQueryType {
  NONE = 'none',
  FUNDRAISINNG = 'fundraising',
  MARKETING = 'marketing'
}
// Define the user model
export interface IDatasetModel {
  queryType: IQueryType
  question: string
  isLoading: boolean
  threadId: number
  datasets: undefined | Array<ISingleDataset>
  isReplaceQuestion: boolean
  maxSearchLimit: boolean
  isExaDataset: boolean
  exaSearching: boolean
  showNoResultText: boolean
  showPopularQusetions: boolean
  randomQuestions: string[]
  setRandomQuestions: Function | any
  setShowPopularQusetion: Function | any
  setMaxSearchLimit: Function | any
  setLoading: Function | any
  createNewQuestion: Function | any
  updateAnswer: Function | any
  clearData: Function | any
  setThreadId: Function | any
  setHistory: Function | any
  clearQuestion: Function | any
  setReplaceQuestion: Function | any
  updateSummary: Function | any
  setExaDataset: Function | any
  setExaSearching: Function | any
  setShowNoResultText: Function | any
  setQuryType: Function | any
}
export interface IShortSummary {
  id: number
  text: string
}
export interface ISingleDataset {
  loading: boolean
  historyId: number
  question: string
  shortDescription: string
  shortSummary: IShortSummary[] | string[] | any
  summaryLoading: boolean
  result: Array<WeaviateResult>
}
export interface IReplaceQuestion {
  isReplaceQuestion: boolean
  question: string
}

const DatasetModel: IDatasetModel = {
  queryType: IQueryType.FUNDRAISINNG,
  question: '',
  isLoading: false,
  exaSearching: false,
  threadId: 0,
  datasets: undefined,
  isReplaceQuestion: false,
  maxSearchLimit: false,
  isExaDataset: false,
  showNoResultText: false,
  showPopularQusetions: false,
  randomQuestions: [],
  setRandomQuestions: action((state: any, payload: any) => {
    state.randomQuestions = payload
  }),
  setShowPopularQusetion: action((state: any, payload: any) => {
    state.showPopularQusetions = payload
  }),
  setMaxSearchLimit: action((state: any, payload: any) => {
    state.maxSearchLimit = payload
  }),
  setLoading: action((state: any, payload: any) => {
    state.isLoading = payload
  }),
  setExaSearching: action((state: any, payload: any) => {
    state.exaSearching = payload
  }
  ),
  createNewQuestion: action((state: any, payload: string) => {
    state.question = payload
    state.isReplaceQuestion = false
    let payloadBody = {
      loading: true,
      summaryLoading: true,
      threadId: state.threadId,
      question: payload
    }
    if (state.datasets) {
      state.datasets.unshift(payloadBody)
    } else {
      state.datasets = [payloadBody]
    }
  }),
  updateAnswer: action((state: any, payload: SearchResponse) => {
    if (state.datasets) {
      let payloadBody = {
        ...state.datasets[0],
        ...payload,
        loading: false,
      }
      state.question = ''
      state.datasets[0] = payloadBody
    }
    state.isLoading = false
  }),
  updateSummary: action((state: any, payload: IShortSummary[]) => {
    if (state.datasets) {
      let payloadBody = {
        ...state.datasets[0],
        shortSummary: payload,
        summaryLoading: false,
      }
      state.question = ''
      state.datasets[0] = payloadBody
    }
    state.isLoading = false
  }),
  clearData: action((state: any) => {
    state.question = ''
    state.threadId = 0
    state.datasets = undefined
    state.isLoading = false
    state.isReplaceQuestion = false
  }),
  setThreadId: action((state: any, payload: number) => {
    state.threadId = payload
    state.isLoading = false
  }),
  setHistory: action((state: any, payload: ISingleDataset[]) => {
    state.datasets = payload
  }),
  clearQuestion: action((state: any) => {
    state.question = ''
  }),
  setReplaceQuestion: action((state: any, payload: IReplaceQuestion) => {
    state.isReplaceQuestion = payload.isReplaceQuestion
    state.question = payload.question
  }),
  setExaDataset: action((state: any, payload: boolean) => {
    state.isExaDataset = payload
  }),
  setShowNoResultText: action((state: any, payload: boolean) => {
    state.showNoResultText = payload
  }
  ),
  setQuryType: action((state: any, payload: IQueryType) => {
    state.queryType = payload
  })
};

export default DatasetModel