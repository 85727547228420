import { Box, styled, Typography } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { ISignInFormValues } from "../../interfaces/ISignInFormValues";
import FormWrapper from "../common/FormWrapper";
import { FormAlert } from "./FormAlert";
import { FormButton } from "./FormButton";
import { FormField } from "./FormField";
import { FormTitle } from "./FormTitle";
import SocialLogin from "./SocialLogin";

const SignInForm = () => {
	const navigate = useNavigate();
	const { showSnackbar } = useAlert();
	const { handleLogin, loading, error, setError } = useAuth();

	const signInFormValues: ISignInFormValues = {
		email: "",
		password: "",
	};

	const [initialValues, setInitialValues] = useState(signInFormValues);

	const validationSchema = yup.object().shape({
		email: yup.string().email("Invalid email").required().label("Email"),
		password: yup.string().required().label("Password"),
	});

	const onSubmit = (
		values: ISignInFormValues,
		actions: FormikHelpers<ISignInFormValues>
	) => {
		handleSignIn(values, actions);
	};

	const handleSignIn = async (
		values: ISignInFormValues,
		actions: FormikHelpers<ISignInFormValues>
	) => {
		try {
			actions.setSubmitting(true);
			setInitialValues(values);
			const response = await handleLogin(values);
			if (response) {
				navigate("/");
			}
		} catch (error: any) {
			showSnackbar(error?.message, error);
		} finally {
			actions.setSubmitting(false);
		}
	};

	const handleForgotPassword = () => {
		navigate("/forgot-password");
	};

	const handleSignUpClick = () => {
		navigate("/signup");
	};

	return (
		<Box
			width={"100%"}
			display="flex"
			flexDirection="column"
			alignItems="center"
		>
			<FormWrapper>
				{!loading && error && (
					<FormAlert error={error} setError={setError} />
				)}
				<FormTitle title={"Login"} />
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
					enableReinitialize
				>
					{() => {
						return (
							<Form
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<FormField
									name="email"
									label="Email"
									placeholder="Email"
								/>
								<FormField
									name="password"
									label="Password"
									placeholder="Password"
									type="password"
								/>

								<ForgotPassword
									sx={{
										fontSize: "12px",
										lineHeight: "24px",
										color: "#1472FF",
										fontFamily: "Inter",
										fontWeight: 500,
									}}
									onClick={handleForgotPassword}
								>
									Forgot password?
								</ForgotPassword>

								<FormButton
									label={"Log in"}
									loading={loading}
								/>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										marginBottom: "32px",
										width: "100%",
									}}
								>
									<SocialLogin />
								</Box>

								<Typography
									textAlign="center"
									color={"#A6A8AC"}
									fontSize={"12px"}
									lineHeight={"24px"}
									fontFamily={"Inter"}
									fontWeight={500}
									// mt={2}
								>
									Don&apos;t have an account?
									<Typography
										component={"span"}
										// ml={1}
										// color="primary"
										display="inline-block"
										onClick={handleSignUpClick}
										sx={{
											cursor: "pointer",
											color: "#1472FF",
											fontWeight: 500,
											fontFamily: "Inter",
											fontSize: "12px",
											lineHeight: "24px",
											textAlign: "center",
											"&:hover": {
												textDecoration: "underline",
											},
										}}
									>
										&nbsp;Sign Up
									</Typography>
								</Typography>
							</Form>
						);
					}}
				</Formik>
			</FormWrapper>
		</Box>
	);
};

export default SignInForm;

const ForgotPassword = styled(Typography)(({ theme }) => ({
	textAlign: "right",
	color: theme.palette.primary.main,
	cursor: "pointer",
	alignSelf: "flex-end",
	"&:hover": {
		textDecoration: "underline",
	},
}));
