import PageWrapper from "../components/common/PageWrapper";
import SignInForm from "../components/form/SignInForm";

const SignInPage = () => {
  return (
    <PageWrapper>
      <SignInForm />
    </PageWrapper>
  );
};

export default SignInPage;
