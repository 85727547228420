import { Box, Typography } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { IVerifyEmailValues } from "../../interfaces/IVerifyEmailValues";
import { getFromLocalStorage } from "../../utils";
import { NEW_USER_INFO } from "../../utils/constants";
import FormWrapper from "../common/FormWrapper";
import Timer from "../timer";
import { FormAlert } from "./FormAlert";
import { FormButton } from "./FormButton";
import { FormField } from "./FormField";
import FormTitle from "./FormTitle";

const VerifyEmailForm = () => {
	const navigate = useNavigate();
	const { showSnackbar } = useAlert();
	const {
		loading,
		error,
		handleConfirmEmail,
		handleResendCode,
		resendCountdownStart,
		setResendCountdownStart,
	} = useAuth();
	const [email, setEmail] = useState<string | null>(null);

	useEffect(() => {
		const userInfo = getFromLocalStorage(NEW_USER_INFO);
		if (userInfo) {
			setEmail(userInfo.email);
		}
	}, []);

	const verifyEmailValues: IVerifyEmailValues = {
		email: email || "",
		code: "",
	};

	useEffect(() => {
		const userInfo = getFromLocalStorage(NEW_USER_INFO);
		if (userInfo) {
			setEmail(userInfo.email);
			setInitialValues({ ...verifyEmailValues, email: userInfo.email });
		}
	}, []);

	const [initialValues, setInitialValues] = useState(verifyEmailValues);

	const validationSchema = yup.object().shape({
		code: yup.string().required().label("Code"),
		email: yup.string().email("Invalid email").required().label("Email"),
	});

	const onSubmit = async (
		values: IVerifyEmailValues,
		actions: FormikHelpers<IVerifyEmailValues>
	) => {
		try {
			actions.setSubmitting(true);
			setInitialValues(values);
			setResendCountdownStart(false);
			const response = await handleConfirmEmail(values);
			if (response) {
				// navigate("/");
				// console.log("handleConfirm Emailresponse", response);
			}
		} catch (error: any) {
			showSnackbar(error?.message, { variant: "error" });
		} finally {
			actions.setSubmitting(false);
		}
	};

	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<FormWrapper>
				{!loading && error && <FormAlert error={error} />}
				<FormTitle title={"Verify email"} />
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
					enableReinitialize
				>
					{() => {
						return (
							<Form>
								{!email && (
									<FormField
										name="email"
										label="Email"
										placeholder="Email"
									/>
								)}
								<FormField
									name="code"
									label="Code"
									placeholder="Code"
								/>
								<Typography textAlign="right" my={2}>
									Didn&apos;t receive the code?
									{resendCountdownStart ? (
										<Timer
											setResendCountdownStart={
												setResendCountdownStart
											}
										/>
									) : (
										<Typography
											component={"span"}
											display="inline-block"
											ml={1}
											color="primary"
											sx={{
												cursor: "pointer",
												"&:hover": {
													textDecoration: "underline",
												},
											}}
											onClick={handleResendCode}
										>
											Resend
										</Typography>
									)}
								</Typography>

								<FormButton
									label={"Verify"}
									loading={loading}
								/>
							</Form>
						);
					}}
				</Formik>
			</FormWrapper>
		</Box>
	);
};

export default VerifyEmailForm;
