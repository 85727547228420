/* tslint:disable */
/* eslint-disable */
/**
 * Main Service API
 * Main Service API
 *
 * The version of the OpenAPI document: 2.11.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChatHistoryList } from '../models';
// @ts-ignore
import { ChatHistoryQuestionsResponse } from '../models';
// @ts-ignore
import { ChatHistoryRequest } from '../models';
// @ts-ignore
import { ChatHistoryResponse } from '../models';
// @ts-ignore
import { SearchResponse } from '../models';
// @ts-ignore
import { TakeawayResponse } from '../models';
// @ts-ignore
import { TakeawaysRequest } from '../models';
// @ts-ignore
import { UserFeedbackRequest } from '../models';
/**
 * ChatHistoryApi - axios parameter creator
 * @export
 */
export const ChatHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChatHistoryRequest} chatHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExaSearchResult: async (chatHistoryRequest: ChatHistoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatHistoryRequest' is not null or undefined
            assertParamExists('getExaSearchResult', 'chatHistoryRequest', chatHistoryRequest)
            const localVarPath = `/main/chatHistory/exaSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryByAdmin: async (limit?: string, offset?: string, userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/chatHistory/getHistoryByAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryByUser: async (limit?: string, offset?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/chatHistory/getHistoryByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionsOnly: async (limit?: string, offset?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/chatHistory/getQuestionsOnly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChatHistoryRequest} chatHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchResult: async (chatHistoryRequest: ChatHistoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatHistoryRequest' is not null or undefined
            assertParamExists('getSearchResult', 'chatHistoryRequest', chatHistoryRequest)
            const localVarPath = `/main/chatHistory/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {TakeawaysRequest} takeawaysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTakeaways: async (takeawaysRequest: TakeawaysRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeawaysRequest' is not null or undefined
            assertParamExists('getTakeaways', 'takeawaysRequest', takeawaysRequest)
            const localVarPath = `/main/chatHistory/getTakeaways`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(takeawaysRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTakeawaysByChId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTakeawaysByChId', 'id', id)
            const localVarPath = `/main/chatHistory/getTakeawaysByChId/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreadHistoryByUser: async (threadId: string, limit?: string, offset?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('getThreadHistoryByUser', 'threadId', threadId)
            const localVarPath = `/main/chatHistory/getThreadHistoryByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (threadId !== undefined) {
                localVarQueryParameter['threadId'] = threadId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreadId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/chatHistory/getThreadId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} userId 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreadedHistoryByAdmin: async (threadId: string, userId: string, limit?: string, offset?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('getThreadedHistoryByAdmin', 'threadId', threadId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getThreadedHistoryByAdmin', 'userId', userId)
            const localVarPath = `/main/chatHistory/getThreadedHistoryByAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (threadId !== undefined) {
                localVarQueryParameter['threadId'] = threadId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserFeedbackRequest} userFeedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserFeedback: async (userFeedbackRequest: UserFeedbackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userFeedbackRequest' is not null or undefined
            assertParamExists('saveUserFeedback', 'userFeedbackRequest', userFeedbackRequest)
            const localVarPath = `/main/chatHistory/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userFeedbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatHistoryApi - functional programming interface
 * @export
 */
export const ChatHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChatHistoryRequest} chatHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExaSearchResult(chatHistoryRequest: ChatHistoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExaSearchResult(chatHistoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoryByAdmin(limit?: string, offset?: string, userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatHistoryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoryByAdmin(limit, offset, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoryByUser(limit?: string, offset?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatHistoryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoryByUser(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionsOnly(limit?: string, offset?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatHistoryQuestionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionsOnly(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChatHistoryRequest} chatHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchResult(chatHistoryRequest: ChatHistoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchResult(chatHistoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {TakeawaysRequest} takeawaysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTakeaways(takeawaysRequest: TakeawaysRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TakeawayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTakeaways(takeawaysRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTakeawaysByChId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TakeawayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTakeawaysByChId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThreadHistoryByUser(threadId: string, limit?: string, offset?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatHistoryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThreadHistoryByUser(threadId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThreadId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThreadId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} userId 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThreadedHistoryByAdmin(threadId: string, userId: string, limit?: string, offset?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatHistoryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThreadedHistoryByAdmin(threadId, userId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserFeedbackRequest} userFeedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveUserFeedback(userFeedbackRequest: UserFeedbackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveUserFeedback(userFeedbackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatHistoryApi - factory interface
 * @export
 */
export const ChatHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {ChatHistoryRequest} chatHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExaSearchResult(chatHistoryRequest: ChatHistoryRequest, options?: any): AxiosPromise<SearchResponse> {
            return localVarFp.getExaSearchResult(chatHistoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryByAdmin(limit?: string, offset?: string, userId?: string, options?: any): AxiosPromise<ChatHistoryList> {
            return localVarFp.getHistoryByAdmin(limit, offset, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryByUser(limit?: string, offset?: string, options?: any): AxiosPromise<ChatHistoryList> {
            return localVarFp.getHistoryByUser(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionsOnly(limit?: string, offset?: string, options?: any): AxiosPromise<ChatHistoryQuestionsResponse> {
            return localVarFp.getQuestionsOnly(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatHistoryRequest} chatHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchResult(chatHistoryRequest: ChatHistoryRequest, options?: any): AxiosPromise<SearchResponse> {
            return localVarFp.getSearchResult(chatHistoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {TakeawaysRequest} takeawaysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTakeaways(takeawaysRequest: TakeawaysRequest, options?: any): AxiosPromise<TakeawayResponse> {
            return localVarFp.getTakeaways(takeawaysRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTakeawaysByChId(id: number, options?: any): AxiosPromise<TakeawayResponse> {
            return localVarFp.getTakeawaysByChId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreadHistoryByUser(threadId: string, limit?: string, offset?: string, options?: any): AxiosPromise<ChatHistoryList> {
            return localVarFp.getThreadHistoryByUser(threadId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreadId(options?: any): AxiosPromise<number> {
            return localVarFp.getThreadId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} userId 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreadedHistoryByAdmin(threadId: string, userId: string, limit?: string, offset?: string, options?: any): AxiosPromise<ChatHistoryList> {
            return localVarFp.getThreadedHistoryByAdmin(threadId, userId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserFeedbackRequest} userFeedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserFeedback(userFeedbackRequest: UserFeedbackRequest, options?: any): AxiosPromise<ChatHistoryResponse> {
            return localVarFp.saveUserFeedback(userFeedbackRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatHistoryApi - object-oriented interface
 * @export
 * @class ChatHistoryApi
 * @extends {BaseAPI}
 */
export class ChatHistoryApi extends BaseAPI {
    /**
     * 
     * @param {ChatHistoryRequest} chatHistoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public getExaSearchResult(chatHistoryRequest: ChatHistoryRequest, options?: AxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).getExaSearchResult(chatHistoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [limit] 
     * @param {string} [offset] 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public getHistoryByAdmin(limit?: string, offset?: string, userId?: string, options?: AxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).getHistoryByAdmin(limit, offset, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [limit] 
     * @param {string} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public getHistoryByUser(limit?: string, offset?: string, options?: AxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).getHistoryByUser(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} [limit] 
     * @param {string} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public getQuestionsOnly(limit?: string, offset?: string, options?: AxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).getQuestionsOnly(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatHistoryRequest} chatHistoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public getSearchResult(chatHistoryRequest: ChatHistoryRequest, options?: AxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).getSearchResult(chatHistoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {TakeawaysRequest} takeawaysRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public getTakeaways(takeawaysRequest: TakeawaysRequest, options?: AxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).getTakeaways(takeawaysRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public getTakeawaysByChId(id: number, options?: AxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).getTakeawaysByChId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {string} [limit] 
     * @param {string} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public getThreadHistoryByUser(threadId: string, limit?: string, offset?: string, options?: AxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).getThreadHistoryByUser(threadId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public getThreadId(options?: AxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).getThreadId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {string} userId 
     * @param {string} [limit] 
     * @param {string} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public getThreadedHistoryByAdmin(threadId: string, userId: string, limit?: string, offset?: string, options?: AxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).getThreadedHistoryByAdmin(threadId, userId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserFeedbackRequest} userFeedbackRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public saveUserFeedback(userFeedbackRequest: UserFeedbackRequest, options?: AxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).saveUserFeedback(userFeedbackRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
