import { getChatHistoryService } from "../services/apiHelpers";
import { useSnackbar } from "notistack";
import { useStoreActions, useStoreState } from "easy-peasy";
import { ChatHistoryResponse } from "../openapi";
import { IAddNewHistory } from "../redux/models/chatHistoryModel";
function getThisMonthHistory(data: ChatHistoryResponse[]) {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  return data.filter(item => {
    const messageDate = new Date(item.createdAt);
    return messageDate.getFullYear() === year && messageDate.getMonth() === month;
  });
}
const useHistory = () => {
  const { enqueueSnackbar } = useSnackbar();


  /** REDUX */
  const historyActions = useStoreActions((actions: any) => actions.chatHistory)
  const historyStates = useStoreState((states: any) => states.chatHistory)
  const setFeedbackData = useStoreActions((actions: any) => actions.feedback.setData)

  const getChatHistory = async (limit: string = "30", offset: string = "0") => {
    historyActions.setLoading(true)
    try {
      const service = await getChatHistoryService();
      const response = await service.getHistoryByUser(limit, offset);
      if ((response.status === 200 || response.status === 201) && response.data) {
        const history = response.data.history.sort((a: ChatHistoryResponse, b: ChatHistoryResponse) => {
          return b.id - a.id
        })
        historyActions.setData({ historyData: history })
        setFeedbackData(history)
        const thisMonthSearches = getThisMonthHistory(history)
        historyActions.setSearchNumberThisMonth(thisMonthSearches.length)
      }
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
        variant: "error",
      });
      historyActions.setData({ historyData: [] })
    } finally {
      historyActions.setLoading(false)
    }
  };

  const addChatHistory = (payload: IAddNewHistory) => {
    historyActions.addData(payload)
  };

  const clearChatHistory = () => {
    historyActions.clearData()
  };
  const setDrwaerOpen = (payload: boolean) => {
    historyActions.setDrwaerOpen(payload)
  }
  return {
    historyData: historyStates.historyData,
    isLoading: historyStates.isLoading,
    isDrwaerOpen: historyStates.isDrwaerOpen,
    searchNumberThisMonth: historyStates.searchNumberThisMonth,
    getChatHistory,
    addChatHistory,
    clearChatHistory,
    setDrwaerOpen
  };
};

export default useHistory;
