/* eslint-disable react-hooks/exhaustive-deps */
import { AlertColor } from "@mui/material";
import { Auth } from "aws-amplify";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IResetPasswordStep1Form } from "../interfaces/IResetPasswordStep1Form";
import { IResetPasswordStep2Form, ResetNewPasswordFormProps } from "../interfaces/IResetPasswordStep2Form";
import { ISignInFormValues } from "../interfaces/ISignInFormValues";
import { ISignUpFormValues } from "../interfaces/ISignUpFormValues";
import { IVerifyEmailValues } from "../interfaces/IVerifyEmailValues";
import { UserCreateRequest, UserType } from "../openapi";

import {
  getFromLocalStorage,
  removeFromLocalStorage,
  saveInLocalStorage,
} from "../utils";
import {
  FORGOT_EMAIL,
  AUTH_TMP_MSG,
  NEW_USER_INFO,
  AUTH_USER_INFO,
  RESET_NEW_PASS_OBJ,
  RESET_NEW_PASS_EMAIL,
  RESET_NEW_OLD_PASS
} from "../utils/constants";
import { useStoreActions, useStoreState } from 'easy-peasy'
import { getCreateUserService, getUserService } from "../services/apiHelpers";
import useDataset from "./useDataset";
import useHistory from "./useHistory";
import { BroadcastChannel } from 'broadcast-channel';

export interface IError {
  message: string;
  severity: AlertColor;
  redirectUrl?: string;
  pageLabel?: string;
}

interface CognitoToken extends JwtPayload {
  sub: string;
  "cognito:groups": string[];
}


const logoutChannel = new BroadcastChannel('logout');


const useAuth = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<IError | null>(null);
  const [resendCountdownStart, setResendCountdownStart] =
    useState<boolean>(false);

  let navigate = useNavigate();
  const { clearDataset, setMaxSearchLimit } = useDataset()
  const { clearChatHistory } = useHistory()

  /** REDUX */
  const authActions = useStoreActions((actions: any) => actions.auth)
  const authUser = useStoreState((state: any) => state.auth)

  useEffect(() => {
    getAuthTmpMsg()

    logoutChannel.onmessage = async () => {
      await Auth.signOut();
      authActions.logout();
      clearDataset()
      clearChatHistory()
      removeFromLocalStorage(AUTH_USER_INFO)
      // logoutChannel.close();
      // window.location.reload()
    }
  }, [])


  const handleLogout = async () => {
    try {
      await Auth.signOut();
      authActions.logout();
      clearDataset()
      clearChatHistory()
      removeFromLocalStorage(AUTH_USER_INFO)
      setMaxSearchLimit(false)
      logoutChannel.postMessage("Logout")
    } catch (error: any) {
      console.error('handleLogout error==', error)
    }
  }

  const isInCustomerGroup = (user: any) => {
    try {
      const decode: CognitoToken = jwt_decode(
        user.signInUserSession.accessToken.jwtToken
      );
      const customer = decode["cognito:groups"][0];
      return customer === "Customer";
    } catch (error) {
      return true;
    }
  };

  const handleLogin = async (values: ISignInFormValues) => {
    const { email, password } = values;
    setLoading(true);
    setError(null);
    try {
      const response = await Auth.signIn(email, password);
      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setError({
          message: "Please reset your password",
          severity: "info",
        });
        saveInLocalStorage(RESET_NEW_OLD_PASS, password);
        saveInLocalStorage(RESET_NEW_PASS_OBJ, response);
        saveInLocalStorage(RESET_NEW_PASS_EMAIL, email)
        navigate("/set-new-password");
      }
      const isCustomer = isInCustomerGroup(response);
      if (!isCustomer) {
        const groups = getUserGroups(response);
        await Auth.signOut();
        if (groups.includes("Admin")) {
          setError({
            message:
              "Provided email is associated with admin account. You can not use admin account in customer panel",
            severity: "error",
          });
        } else {
          setError({
            message: "You can't use this email in customer panel",
            severity: "error",
          });
        }
        return false;
      }
      // console.log('response.attributes==', response.attributes)
      const authUserInfo = {
        userEmail: response.attributes.email,
        userName: !response.attributes?.family_name || response.attributes?.family_name === 'Undefined' ? response.attributes?.given_name : response.attributes?.given_name + ' ' + response.attributes?.family_name
      }
      saveInLocalStorage(AUTH_USER_INFO, authUserInfo)

      authActions.login(authUserInfo);

      const service = getUserService();
      const responseUserFromDb = await service.getUserByEmail(email)
      if (responseUserFromDb.status === 200 || responseUserFromDb.status === 201) {
        authActions.setUserInfo(responseUserFromDb.data)
      }
      return true;
    } catch (error: any) {
      setError({
        message: "Incorrect username or password",
        severity: "error",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleSocialLogin = async (values: { name: string, email: string, sub: string }) => {
    // console.log('handleSocialLogin values==', values)
    const { name, email, sub } = values;

    setLoading(true);
    setError(null);
    try {

      const service = getUserService();
      const response = await service.getUserByEmail(email)
      if (response.status === 200 || response.status === 201) {
        // console.log('getUserByEmail response.data==', response.data)
        authActions.setUserInfo(response.data)
      } else {
        // console.log('UserCreateRequest response==', response)
        const requestBody: UserCreateRequest = {
          email: email,
          userType: UserType.Customer,
          firstName: name,
          lastName: "",
          cognitoUserId: sub,
          stripeCustomerId: "none",
          subscriptionType: "Free",
        }

        const createService = getCreateUserService()
        const apiRes = await createService.createUser(requestBody)
        if (apiRes.data && apiRes.status === 201) {
          authActions.setUserInfo(apiRes.data)
        }
      }
      const authUserInfo = {
        userEmail: email,
        userName: name
      }
      saveInLocalStorage(AUTH_USER_INFO, authUserInfo)

      authActions.login(authUserInfo);

      // const service = getUserService();
      // const responseUserFromDb = await service.getUserByEmail(email)
      // if (responseUserFromDb.status === 200 || responseUserFromDb.status === 201) {
      //   authActions.setUserInfo(responseUserFromDb.data)
      // }
      return true;
    } catch (error: any) {
      console.error('handleSocialLogin error==', error)
      if (error.response && error.response.status === 404) {
        try {

          console.log('error.response.status === 404')
          const requestBody: UserCreateRequest = {
            email: email,
            userType: UserType.Customer,
            firstName: name,
            lastName: "",
            cognitoUserId: sub,
            stripeCustomerId: "none",
            subscriptionType: "Free",
          }

          const createService = getCreateUserService()
          const apiRes = await createService.createUser(requestBody)
          if (apiRes.data && apiRes.status === 201) {
            authActions.setUserInfo(apiRes.data)
          }

          const authUserInfo = {
            userEmail: email,
            userName: name
          }
          saveInLocalStorage(AUTH_USER_INFO, authUserInfo)

          authActions.login(authUserInfo);
        } catch (error) {
          console.error('handleSocialSignup error==', error)
        }
      }
      setError({
        message: "Incorrect username or password",
        severity: "error",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  const getUserInfo = async () => {
    try {

      const service = getUserService();
      const response = await service.getUserByEmail(authUser.userEmail)
      if (response.status === 200 || response.status === 201) {
        authActions.setUserInfo(response.data)
      }
    } catch (error) {
      console.error('getUserInfo error==', error)
      handleLogout()
      setError({
        message: "User not recognized! Please login again.",
        severity: "error",
      });
      setAuthTmpMsg("User not recognized! Please login again.", 'error')

    }
  }
  const getUserGroups = (user: any) => {
    const decode: CognitoToken = jwt_decode(
      user.signInUserSession.accessToken.jwtToken
    );
    const groups = decode["cognito:groups"];
    return groups;
  };

  const handleConfirmEmail = async (values: IVerifyEmailValues) => {
    try {
      setLoading(true);
      const { email, code } = values;
      const userInfo = getFromLocalStorage(NEW_USER_INFO);
      if (!userInfo) {
        return "error";
      }
      const response = await Auth.confirmSignUp(email, code);
      if (response !== "SUCCESS") {
        return "error";
      }

      const requestBody: UserCreateRequest = {
        email: email,
        userType: UserType.Customer,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName ?? "",
        cognitoUserId: userInfo.cognitoUserId,
        stripeCustomerId: "none",
        subscriptionType: "Free",

      }
      const service = getCreateUserService()
      const apiRes = await service.createUser(requestBody)
      if (apiRes.data && apiRes.status === 201) {
        removeFromLocalStorage(NEW_USER_INFO);
        // setAuthTmpMsg("Email verified successfully, login to explore", 'info')

        const loginRes = await handleLogin({
          email,
          password: userInfo.password
        })
        if (loginRes) {
          navigate('/')
        } else {
          return false;
        }
      }
      return false;
    } catch (error: any) {
      setError({
        message: error?.message ?? error.toString(),
        severity: "error",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleCreateUser = async (
    values: ISignUpFormValues
    //actions: FormikHelpers<ISignUpFormValues>
  ) => {
    setLoading(true);
    try {
      const { email, password, firstName, lastName } = values;
      /** CREATE A NEW ACCOUNT INTO AWS COGNITO */
      const response = await Auth.signUp({
        username: email,
        password,
        attributes: {
          given_name: firstName,
          family_name: lastName ?? "",
        },
      });

      /** CHECK IF REQUIRED EMAIL VERIFICATION FROM COGNITO */
      if (!response.userConfirmed) {
        saveInLocalStorage(NEW_USER_INFO, {
          ...values,
          cognitoUserId: response.userSub,
          userType: "customer",
        });
        setAuthTmpMsg("We have sent a verification code to your email, please check your email", 'info')
        return true;
      }

      /** AUTO VERIFY ACCOUNT FROM COGNITO THEN CREATE USER ACCOUNT TO BACKEND */
      const requestBody: UserCreateRequest = {
        email: email,
        userType: UserType.Customer,
        firstName: firstName,
        lastName: lastName ?? "",
        cognitoUserId: response.userSub,
        stripeCustomerId: "none",
        subscriptionType: "Free",
      }
      const service = getCreateUserService()
      const apiRes = await service.createUser(requestBody)
      if (apiRes.data && apiRes.status === 201) {
        await handleLogin({
          email,
          password
        })
        // if (loginRes) {
        //   navigate('/')
        // }
      }
    } catch (error: any) {
      setError({
        message: error?.message ?? error.toString(),
        severity: "error",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    const userInfo = getFromLocalStorage(NEW_USER_INFO)
    setError(null);
    if (userInfo && userInfo.email) {
      try {
        await Auth.resendSignUp(userInfo.email);
        setResendCountdownStart(true);
        setError({
          message: "We have sent another verification code in your email.",
          severity: 'info'
        })
      } catch (error: any) {
        setError({
          message: error?.message ?? error.toString(),
          severity: "error",
        });
      }
    } else {
      setError({
        message: "Something went wrong",
        severity: "error",
      });
    }
  };


  const getAuthTmpMsg = () => {
    const tmpMsg = getFromLocalStorage(AUTH_TMP_MSG)
    if (tmpMsg) {
      setError({
        ...tmpMsg,
      })
      removeFromLocalStorage(AUTH_TMP_MSG)
    }
  }
  const setAuthTmpMsg = (message: string, severity: string) => {
    saveInLocalStorage(AUTH_TMP_MSG, {
      message,
      severity,
    })
  }


  const getCode = async (values: IResetPasswordStep1Form) => {
    setError(null);
    setLoading(true)
    try {
      await Auth.forgotPassword(values.email);
      setAuthTmpMsg("We have sent verification code in your email.", 'info')
      saveInLocalStorage(FORGOT_EMAIL, values.email);
      navigate("/reset-password");
    } catch (error: any) {
      setError({
        message: error?.message ?? error.toString(),
        severity: "error",
      });
    } finally {
      setLoading(false)
    }
  };

  const getResetPasswordCodeByEmail = async (
    values: IResetPasswordStep1Form
  ) => {
    getCode(values);
  };

  const resetPasswordWithCode = async (values: IResetPasswordStep2Form) => {
    const { email, code, password } = values;
    setLoading(true);
    setError(null);
    try {
      const response = await Auth.forgotPasswordSubmit(email, code, password);
      if (response) {
        setAuthTmpMsg("Password has been changed successfully!", 'info')
        removeFromLocalStorage(FORGOT_EMAIL);
      }
      return true;
    } catch (error: any) {
      setError({
        message: error?.message ?? error.toString(),
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };


  const resetNewPassword = async (values: ResetNewPasswordFormProps) => {
    const { email, password, oldPassword } = values;
    // console.log('resetNewPassword values==', values)
    setLoading(true);
    setError(null);
    try {
      const previousPassword = getFromLocalStorage(RESET_NEW_OLD_PASS) ?? oldPassword;
      const userInfo = await Auth.signIn(email, previousPassword);

      // console.log('resetNewPassword userInfo==', userInfo)
      const response = await Auth.completeNewPassword(userInfo, password);
      if (response) {
        setAuthTmpMsg("Password has been reset successfully!", 'info')
        removeFromLocalStorage(RESET_NEW_PASS_OBJ);
        removeFromLocalStorage(RESET_NEW_PASS_EMAIL);
        removeFromLocalStorage(RESET_NEW_OLD_PASS);
        navigate("/signin");
      }
      return true;
    } catch (error: any) {
      setError({
        message: error?.message ?? error.toString(),
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  return {
    isAuthenticated: authUser.isAuthenticated,
    userEmail: authUser.userEmail,
    userName: authUser.userName,
    userInfo: authUser.userInfo,
    error,
    setError,
    loading,
    handleLogin,
    handleConfirmEmail,
    resendCountdownStart,
    handleResendCode,
    setResendCountdownStart,
    getResetPasswordCodeByEmail,
    resetPasswordWithCode,
    resetNewPassword,
    handleCreateUser,
    handleLogout,
    getAuthTmpMsg,
    getUserInfo,
    handleSocialLogin
  };
};
export default useAuth;

